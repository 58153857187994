import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { Amplify, Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import awsExports from "../aws-exports";

import { useNavigate } from "react-router-dom";
import { getLoginUser } from "pages/employeeSearch/api/Api";

import { PermissionLevelCode } from "../constants/Enum";
import { HttpStatusCode } from "axios";
import { LoginUserType } from "types/LoginUserType";
import { checkAuthenticated } from "auth/checkAuthenticated";

Amplify.configure(awsExports);

const Login = () => {
  const cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    height: "450px",
    width: "400px",
    variant: "outlined",
  };

  const navigator = useNavigate();

  const [loginUserEmail, setLoginUserEmail] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");

  useEffect(() => {
    // 認証処理を実施
    checkAuthenticated(navigator)
      .then((data) => {
        if (data) {
          setLoginUserEmail(data.email);
        }
      })
      .catch((error) => {
        console.log("未ログイン");
      });
  }, []);

  useEffect(() => {
    if (loginUserEmail) {
      (async () => {
        getLoginUser(loginUserEmail)
          .then((response) => {
            const { data, status } = response;
            if (status === HttpStatusCode.Ok) {
              navigator(determineNextPage(data), {
                state: {
                  loginUserInfo: data,
                  currentOrganization: data.permissionDtoList.at(0),
                  concurrentScreenFlag: false,
                },
              });
            }
          })
          .catch((error) => {
            if (error.response?.status === HttpStatusCode.Unauthorized) {
              // 許可されていないユーザの場合
              setDialogMessage("アクセス権限がありません。");
            } else {
              // 上記以外のエラーの場合
              setDialogMessage("予期しないエラーが発生しました。");
            }
            setIsOpen(true);
          });
      })();
    }
  }, [loginUserEmail, navigator]);

  /**
   * ダイアログを閉じて、サインアウトする
   */
  const closeDialog = () => {
    setIsOpen(false);
    signOut();
  };

  /**
   * サインアウトする
   */
  const signOut = async () => {
    await Auth.signOut()
      .then(() => {
        console.log("ログアウトしました");
      })
      .catch((error) => {
        console.error("ログアウトに失敗しました", error);
      });
  };

  /**
   * 遷移先を特定する
   *
   * @param loginUser ログインユーザ情報
   * @returns 遷移先パス
   */
  const determineNextPage = (loginUser: LoginUserType) => {
    if (loginUser.permissionDtoList.length >= 2) {
      // 所属部署が複数存在する場合、兼務一覧画面
      return `/concurrentlyposition/${loginUser.dataId}`;
    } else {
      const isSuperVisor =
        loginUser.permissionDtoList.at(0)?.permissionLevelCode !==
        PermissionLevelCode.MYSELF;
      if (isSuperVisor) {
        // 権限保持者の場合、社員検索画面
        return `/employeeSearch`;
      } else {
        // メンバ権限の場合、社員詳細画面
        return `/talentdetail/${loginUser.dataId}`;
      }
    }
  };

  return !loginUserEmail ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={20}
    >
      <Card style={cardStyle}>
        <CardHeader title="ログインページ" />
        <CardActions>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => {
              Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google,
              });
            }}
          >
            Login
          </Button>
        </CardActions>
      </Card>
    </Box>
  ) : (
    <div>
      <Dialog open={isOpen}>
        <DialogTitle>エラー</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Login;
