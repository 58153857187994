import { PermissionLevelCode } from "../../../src/constants/Enum";

const resolveRoleName = (permissionLevelCode: String) => {
  switch (permissionLevelCode) {
    case PermissionLevelCode.ALL_EMPLOYEES:
      return "代表取締役";
    case PermissionLevelCode.AFFILIATED_HEADQUARTERS:
      return "本部長/室長";
    case PermissionLevelCode.AFFILIATED_GENERAL_HEADQUARTERS:
      return "統括部長/本社責任者/事業所責任者";
    case PermissionLevelCode.AFFILIATED_BUSINESS_HEADQUARTERS:
      return "事業部長/部長";
    case PermissionLevelCode.AFFILIATED_GROUP:
      return "グループ長";
    case PermissionLevelCode.MYSELF:
      return "メンバー";
    case PermissionLevelCode.ADMIN:
      return "執行会メンバー";
    default:
      return "";
  }
};

export default resolveRoleName;
