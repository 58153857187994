import BasicInfoCardHeader from "pages/common/BasicInfoCardHeader";
import { Box, CardContent, TextField } from "@mui/material";
import { EmpType } from "types/EmpType";
import { isInputEditing, isRequiredItem } from "pages/common/LayoutFunction";

const Directional = (emp: EmpType) => {
  return (
    <div>
      <>
        <BasicInfoCardHeader
          headerTitle="検定"
          iconType="campaign"
          editable={false}
        />
        <CardContent>
          <Box>
            <TextField
              size="small"
              label="ランク"
              InputProps={{ readOnly: true }}
              sx={[{ width: "260px" }, isInputEditing(false)]}
              type="string"
              value={"A"}
            />
          </Box>
        </CardContent>
      </>
    </div>
  );
};
export default Directional;
