import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ServerError = () => {
  const navigate = useNavigate();

  const cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    height: "200px",
    width: "400px",
    variant: "outlined",
  };

  const onClickLogin = () => {
    navigate("/");
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={20}
    >
      <Card style={cardStyle}>
        <CardHeader title="エラーページ" />
        <CardContent>
          <Box>{"サーバー側でエラーが発生しました。"}</Box>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={onClickLogin}
          >
            OK
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ServerError;
