import { useEffect, useState } from "react";
import BasicInfoCardHeader from "pages/common/BasicInfoCardHeader";
import { Box, CardContent, TextField } from "@mui/material";
import { EmpType } from "types/EmpType";
import { MemoType } from "types/basicInfo/MemoType";
import { getMemo, postMemo } from "./api/Api";
import { isCardEditing, isInputEditing } from "pages/common/LayoutFunction";
import BasicFooterButton from "pages/common/BasicFooterButton";
import {
  handleGetResponse,
  handlePostResponse,
} from "components/handleResponse";
import { IsCardEditable } from "../../../common/IsEditable";
import ToastMsg from "components/ToastMsg";

const Memo = (emp: EmpType) => {
  /* 状態管理パラメータ */
  // 編集状態を管理するフラグ（true：編集中、false：非編集中）
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // データを保存したかを管理するフラグ
  const [isSave, setIsSave] = useState<boolean>(false);

  /* DB値 */
  const [dbMemo, setDbMemo] = useState<MemoType>();

  /* 画面表示項目 */
  const [inputAssignMemo, setAssignMemo] = useState<string>(""); //アサインメモ
  const [inputHrMemo, setHrMemo] = useState<string>(""); //人事メモ

  /**
   * DB値の入力項目反映処理
   *
   * @param dbMemo メモ情報
   */
  const dbDataToInputValues = (dbMemo: MemoType) => {
    setAssignMemo(dbMemo.assignMemo ?? "");
    setHrMemo(dbMemo.hrMemo ?? "");
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    // リロード対象の場合、取得APIを呼び出す
    (async () => {
      await handleGetResponse(getMemo(emp.empDataId))
        .then((data) => {
          setDbMemo(data);
          dbDataToInputValues(data);
          setIsSave(false);
        })
        .catch((error) => {
          console.error("GETリクエストでエラーが発生しました。");
        });
    })();
  }, []);

  /**
   * 「キャンセル」ボタンクリックハンドラー
   */
  const onClickInputClearHandler = () => {
    if (dbMemo) {
      dbDataToInputValues(dbMemo);
    }
    setIsEdit(false);
  };

  /**
   * 編集アイコンクリックハンドラー
   */
  const onClickEditHandler = () => {
    setIsEdit(true);
  };

  /**
   * 「保存」ボタンクリックハンドラー
   */
  const onClickButtonSaveHandler = () => {
    if (dbMemo) {
      // 更新リクエスト時のパラメータを設定する
      const memo: MemoType = {
        dataId: dbMemo.dataId,
        empDataId: emp.empDataId,
        assignMemo: inputAssignMemo,
        hrMemo: inputHrMemo,
        updatedAt: dbMemo.updatedAt,
      };
      (async () => {
        await handlePostResponse(postMemo(memo))
          .then((data) => {
            setDbMemo(data);
            dbDataToInputValues(data);
            setIsSave(true);
          })
          .catch((error) => {
            console.error("POSTリクエストでエラーが発生しました。");
          });
      })();
    }
    setIsEdit(false);
  };

  return (
    <div style={isCardEditing(isEdit)}>
      {dbMemo && (
        <>
          <BasicInfoCardHeader
            headerTitle="メモ"
            onClickEditHandler={() => onClickEditHandler()}
            iconType="note"
            editable={IsCardEditable(emp.isEmployed)}
          />
          <CardContent>
            <Box>
              <TextField
                id="assign_memo"
                label="アサインメモ"
                multiline
                rows={4}
                sx={[{ width: 470 }, isInputEditing(isEdit)]}
                value={inputAssignMemo}
                onChange={(e) => {
                  setAssignMemo(e.target.value);
                }}
                InputProps={{ readOnly: !isEdit }}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="hr_memo"
                label="人事メモ"
                multiline
                rows={4}
                sx={[{ width: 470 }, isInputEditing(isEdit)]}
                value={inputHrMemo}
                onChange={(e) => {
                  setHrMemo(e.target.value);
                }}
                InputProps={{ readOnly: !isEdit }}
              />
            </Box>

            <BasicFooterButton
              isEditing={isEdit}
              mright={1}
              onClickSave={onClickButtonSaveHandler}
              onClickCancel={onClickInputClearHandler}
            />
            <ToastMsg isDisp={isSave} isSuccess={true} />
          </CardContent>
        </>
      )}
    </div>
  );
};

export default Memo;
