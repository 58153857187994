import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { Fragment, useEffect, useState } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "components/Header";
import Summary from "./Summary/Summary";
import BasicInfo from "./basicInfo/BasicInfo";
import Challenging from "./challenging/Challenging";
import Relationship from "./relationship/Relationship";
import resolveRoleName from "pages/common/RoleNameFunction";
// api
import { getBasicInfo } from "./basicInfo/contents/api/Api";
// types
import { BasicInfoType } from "types/basicInfo/BasicInfoType";
import { setErrMsgCallback, setNavigateCallback } from "lib/commonAxios";
import { checkAuthenticated } from "auth/checkAuthenticated";
import { PermissionLevelCode } from "../../constants/Enum";
import ToastMsg from "components/ToastMsg";

const TalentDetail = () => {
  const { dataId } = useParams<string>();
  const [basicInfo, setBasicInfo] = useState<BasicInfoType>();
  const [image, setImage] = useState("");
  const [isError, setIsError] = useState<boolean>(false); // エラーが発生したかのフラグ
  const [isErrorText, setIsErrorText] = useState<string>(""); // エラー内容

  // URL情報
  const location = useLocation();
  const navigator = useNavigate();

  // 退職済みテキスト
  const retired = " (※退職済み)";

  // 入社日をstring型で受け取っているが、年月日表示に変換するために一度Date型に変換
  const [employmentStartDateStr, setEmploymentStartDateStr] =
    useState<string>("");
  const employmentStartDate = new Date(employmentStartDateStr);

  const cardHeaderStyle = createTheme({
    components: {
      MuiCardHeader: {
        styleOverrides: {
          title: {
            fontWeight: 700,
          },
          content: {
            display: "flex",
            alignItems: "center",
          },
        },
      },
    },
  });

  const basicInfoCardStyle = {
    display: "flex",
    transitionDuration: "0.3s",
    variant: "outlined",
  };

  const cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    width: "1600px",
    variant: "outlined",
  };

  // テキストフィールドのベーススタイル
  const textFieldStyle = (marginLeft: number = 0) => {
    return {
      width: "260px",
      PointerEvent: "none",
      ml: marginLeft,
      backgroundColor: "#ebebeb",
    };
  };

  // 画像がない場合はNoImageを表示する関数
  const noImageHandler = () => {
    setImage(`${process.env.REACT_APP_PORTRAIT_PATH}/no-image.jpg`);
  };

  const navigate = useNavigate();

  // apiで500番台のエラーが起きた時、エラー画面に遷移する
  useEffect(() => {
    setNavigateCallback(navigate);
  }, [navigate]);

  // エラーフラグをfalseに戻す。
  useEffect(() => {
    setIsError(false);
  }, [isError]);

  useEffect(() => {
    // カスタムイベントを処理するための関数
    const handleErrorEvent = (event: CustomEvent) => {
      // カスタムイベントからエラーメッセージを取得する
      setIsError(true);
      setIsErrorText(event.detail);
    };
    // カスタムイベントのリスナを追加
    window.addEventListener("requestError", handleErrorEvent as EventListener);

    // コンポーネントがアンマウントされるときにイベントリスナーを削除する
    return () => {
      window.removeEventListener(
        "requestError",
        handleErrorEvent as EventListener
      );
    };
  }, []);

  useEffect(() => {
    // 認証処理を実施
    checkAuthenticated(navigate, location).then((data) => {
      if (data && dataId) {
        // axios通信でemployeeIdに基づく基本情報を取得
        (async () => {
          const { data, status } = await getBasicInfo(Number(dataId));
          if (status === 200) {
            // 正常値が返却された時に行なう処理
            setBasicInfo(data);
            setEmploymentStartDateStr(data.employmentStartDate.toString());
            setImage(`${process.env.REACT_APP_PORTRAIT_PATH}${data.path}`);
          } else if (status === 400) {
            // 400エラーの時に行なう処理
          } else if (status === 500) {
            // 500エラーの時に行なう処理
          }
          // TODO: エラー通信
        })();
      } else {
        // エラー処理
      }
    });
  }, []);

  /**
   * 入社日(yyyy-mm-dd)を年月日表示に変換
   * @param {Date} date
   *  */
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 月は0から始まるため+1する
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}年${month}月${day}日`;
  };
  // 在籍期間(入社年月日＋在籍年数)
  const employmentTenure =
    formatDate(employmentStartDate) + " 〜 " + basicInfo?.tenure;

  /**
   * 戻るボタン押下時の処理
   */
  const backPage = () => {
    if (location.state.concurrentScreenFlag) {
      navigator(
        `/concurrentlyposition/${location.state.loginUserInfo.dataId}`,
        {
          state: {
            loginUserInfo: location.state.loginUserInfo,
            currentOrganization: location.state.currentOrganization,
            concurrentScreenFlag: location.state.concurrentScreenFlag,
          },
        }
      );
    } else {
      navigator("/employeeSearch", {
        state: {
          loginUserInfo: location.state.loginUserInfo,
          currentOrganization: location.state.currentOrganization,
          concurrentScreenFlag: location.state.concurrentScreenFlag,
        },
      });
    }
  };

  /**
   * 戻るボタン表示制御関数
   * ボタン表示：権限レベルがメンバ以外、もしくは兼務一覧画面からの遷移の場合
   * ボタン非表示：権限レベルがメンバ、かつ兼務一覧画面からの遷移でない場合
   */
  const showBackButton = (
    concurrentScreenFlag: boolean,
    permissionLevelCode: String
  ) => {
    return (
      permissionLevelCode !== PermissionLevelCode.MYSELF || concurrentScreenFlag
    );
  };

  /**
   * ボタンテキスト制御関数
   */
  const controllButtonText = (
    concurrentScreenFlag: boolean,
    permissionLevelCode: String
  ) => {
    // 権限レベルがメンバで、兼務一覧画面からの遷移の場合
    if (
      permissionLevelCode === PermissionLevelCode.MYSELF &&
      concurrentScreenFlag
    ) {
      return "兼務一覧へ戻る";
    }
    return "社員一覧へ戻る";
  };

  return (
    <>
      {basicInfo && (
        <>
          <Header
            title="タレントマネジメント詳細"
            username={location?.state?.loginUserInfo.fullName}
          />
          <>
            <ToastMsg isDisp={isError} isSuccess={false} msg={isErrorText} />
          </>
          {showBackButton(
            location.state.concurrentScreenFlag,
            location.state.currentOrganization.permissionLevelCode
          ) && (
            <Button
              variant="contained"
              sx={{
                mt: "20px",
                ml: "10px",
                color: "#1976d2",
                border: "2px solid #1976d2",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={backPage}
            >
              {controllButtonText(
                location.state.concurrentScreenFlag,
                location.state.currentOrganization.permissionLevelCode
              )}
            </Button>
          )}
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <Card style={basicInfoCardStyle} sx={{ ml: 2, mt: 2 }}>
                <Box sx={{ ml: 2, mt: 2, width: 200, height: 200 }}>
                  <img
                    src={image}
                    alt="プロフィール写真"
                    width={200}
                    height={200}
                    onError={noImageHandler}
                  />
                </Box>
                {basicInfo && (
                  <Box>
                    <CardHeader
                      theme={cardHeaderStyle}
                      title={`${basicInfo.employeeName}${
                        basicInfo.employed ? "" : retired
                      }`}
                      subheader={basicInfo.employeeNameKana}
                    />
                    <CardContent>
                      <Box sx={{ display: "block" }}>
                        <Box sx={{ display: "flex" }}>
                          <Button
                            target="_blank"
                            href={`https://orchestra.alhinc.jp/employees/${basicInfo.empNo}`}
                            sx={{ float: "left", textTransform: "none" }}
                          >
                            <LaunchIcon sx={{ fontSize: 20 }} />
                            <span style={{ fontSize: 20 }}>
                              {"Orchestraを開く"}
                            </span>
                          </Button>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <TextField
                            id="prefecture-field"
                            label="所属拠点"
                            defaultValue={basicInfo.location}
                            InputProps={{ readOnly: true }}
                            sx={textFieldStyle()}
                          />
                          <TextField
                            id="employeeId-field"
                            label="社員番号"
                            defaultValue={basicInfo.empNo}
                            InputProps={{ readOnly: true }}
                            sx={textFieldStyle(1)}
                          />
                          <TextField
                            id="contractTypeName-field"
                            label="雇用形態"
                            defaultValue={basicInfo.contractTypeName}
                            InputProps={{ readOnly: true }}
                            sx={textFieldStyle(1)}
                          />
                          <TextField
                            id="email-field"
                            label="Email"
                            defaultValue={basicInfo.email}
                            InputProps={{ readOnly: true }}
                            sx={textFieldStyle(1)}
                          />
                          <TextField
                            id="startDate-field"
                            label="在籍期間"
                            defaultValue={employmentTenure}
                            InputProps={{ readOnly: true }}
                            sx={textFieldStyle(1)}
                          />
                        </Box>
                        <Box>
                          {basicInfo.permissionDtoList.map(
                            (permission, index) => (
                              <Box>
                                <TextField
                                  id={`departmentName-field-${index}`}
                                  label="部署名"
                                  defaultValue={permission.organizationName}
                                  InputProps={{ readOnly: true }}
                                  sx={[{ mt: 2 }, textFieldStyle()]}
                                />
                                <TextField
                                  id={`startDate-field-${index}`}
                                  label="開始日"
                                  defaultValue={permission.startDate}
                                  InputProps={{ readOnly: true }}
                                  sx={[{ mt: 2 }, textFieldStyle(1)]}
                                />
                                <TextField
                                  id="role"
                                  label="役職"
                                  defaultValue={resolveRoleName(
                                    permission.permissionLevelCode
                                  )}
                                  InputProps={{ readOnly: true }}
                                  sx={[{ mt: 2 }, textFieldStyle(1)]}
                                />
                              </Box>
                            )
                          )}
                        </Box>
                      </Box>
                    </CardContent>
                  </Box>
                )}
              </Card>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Card style={cardStyle} sx={{ ml: 2, mt: 2 }}>
              <CardContent>
                <div>
                  <Summary
                    empDataId={Number(dataId)}
                    isEmployed={basicInfo.employed}
                    employStartDate={basicInfo.employmentStartDate}
                  />
                </div>
              </CardContent>
            </Card>
          </Box>
          <Box display="block" alignItems="center" justifyContent="flex-start">
            <BasicInfo
              empDataId={Number(dataId)}
              isEmployed={basicInfo.employed}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Card
              sx={{
                ml: 32,
                mt: 2,
                width: "1060px",
                display: "block",
                transitionDuration: "0.3s",
                variant: "outlined",
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={6} display="flex" justifyContent="center">
                    <Challenging
                      empDataId={Number(dataId)}
                      isEmployed={basicInfo.employed}
                    />
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="center">
                    <Relationship
                      empDataId={Number(dataId)}
                      isEmployed={basicInfo.employed}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default TalentDetail;
