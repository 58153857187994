import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { EmpType } from "../../../types/EmpType";
import PersonNearJoinning from "./contents/PersonNearJoinning";
import Humans from "./contents/Humans";

const Relationship = (emp: EmpType) => {
  const cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    width: "320px",
    variant: "outlined",
  };

  return (
    <Card style={cardStyle} sx={{ border: 1, borderColor: "#008D56" }}>
      <CardHeader
        title="人間関係"
        sx={{ backgroundColor: "#008D56", color: "white" }}
      />
      <CardContent>
        <div>
          <Box>
            <Card sx={{ ml: -0.5, mt: 2, width: "300px", height: "200px" }}>
              <PersonNearJoinning
                empDataId={emp.empDataId}
                isEmployed={emp.isEmployed}
              />
            </Card>
            <Card sx={{ ml: -0.5, mt: 2, width: "300px", height: "250px" }}>
              <Humans empDataId={emp.empDataId} isEmployed={emp.isEmployed} />
            </Card>
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};

export default Relationship;
