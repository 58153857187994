import { PersonNearJoinningType } from "types/relationship/PersonNearJoinningType";
import apiClient from "components/apiClient";

/**
 * 人間関係API
 * @param empDataId 社員データID
 * @returns 人間関係
 */
export const getPersonNearJoin = async (empDataId: number) => {
  return await apiClient.get<PersonNearJoinningType[]>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/near-hire-date`
  );
};
