import { useEffect, useState } from "react";
import BasicInfoCardHeader from "../../../common/BasicInfoCardHeader";
import { Box, CardContent, TextField } from "@mui/material";
import { CareerType } from "../../../../types/basicInfo/CareerType";
import { EmpType } from "../../../../types/EmpType";
import { getCarrer, postCarrer } from "./api/Api";
import { isCardEditing, isInputEditing } from "pages/common/LayoutFunction";
import BasicFooterButton from "../../../common/BasicFooterButton";
import { IsCardEditable } from "../../../common/IsEditable";
import {
  handleGetResponse,
  handlePostResponse,
} from "components/handleResponse";
import ToastMsg from "components/ToastMsg";

const Career = (emp: EmpType) => {
  /* 状態管理パラメータ */
  // 編集状態を管理するフラグ（true：編集中、false：非編集中）
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // データを保存したかを管理するフラグ
  const [isSave, setIsSave] = useState<boolean>(false);

  /* DB値 */
  const [careerData, setCareerData] = useState<CareerType>();

  /* 画面表示項目 */
  const [inputStrength, setStrength] = useState(""); //得意分野・強み

  /**
   * DB値の反映処理
   *
   * @param careerData キャリア情報
   */
  const dbDataToInputValues = (careerData: CareerType) => {
    //入力項目に展開
    setStrength(careerData.strength ?? ""); //得意分野・強み
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    // リロード対象の場合、取得APIを呼び出す
    (async () => {
      await handleGetResponse(getCarrer(emp.empDataId)).then((data) => {
        setCareerData(data);
        dbDataToInputValues(data);
        setIsSave(false);
      });
    })();
  }, []);

  /**
   * 編集アイコンクリックハンドラー
   */
  const onClickEditHandler = () => {
    setIsEdit(true);
  };

  /**
   * 「キャンセル」ボタンクリックハンドラー
   */
  const onClickInputClearHandler = () => {
    if (careerData) {
      dbDataToInputValues(careerData);
    }
    setIsEdit(false);
  };

  /**
   * 「保存」ボタンクリックハンドラー
   */
  const onClickButtonSaveHandler = () => {
    if (careerData) {
      // 更新リクエスト時のパラメータを設定する
      const inputCarrer: CareerType = {
        dataId: careerData?.dataId,
        empDataId: emp.empDataId,
        strength: inputStrength,
        updatedAt: careerData.updatedAt,
      };
      (async () => {
        await handlePostResponse(postCarrer(inputCarrer))
          .then((data) => {
            setCareerData(data);
            dbDataToInputValues(data);
            setIsSave(true);
          })
          .catch((error) => {
            console.error("POSTリクエストでエラーが発生しました。");
          });
      })();
    }
    // 保存押下時に更新APiを起動
    setIsEdit(false);
  };

  return (
    <div style={isCardEditing(isEdit)}>
      {careerData && (
        <>
          <BasicInfoCardHeader
            headerTitle="キャリア"
            onClickEditHandler={() => onClickEditHandler()}
            iconType="run"
            editable={IsCardEditable(emp.isEmployed)}
          />
          <CardContent>
            <Box>
              <TextField
                id="strength"
                label="得意分野・強み"
                multiline
                rows={4}
                sx={[{ width: 470 }, isInputEditing(isEdit)]}
                value={inputStrength}
                onChange={(e) => {
                  setStrength(e.target.value);
                }}
                InputProps={{ readOnly: !isEdit }}
              />
            </Box>

            <BasicFooterButton
              isEditing={isEdit}
              mtop={1}
              mright={1}
              onClickSave={onClickButtonSaveHandler}
              onClickCancel={onClickInputClearHandler}
            />
            <ToastMsg isDisp={isSave} isSuccess={true} />
          </CardContent>
        </>
      )}
    </div>
  );
};

export default Career;
