import {
  Autocomplete,
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BasicInfoCardHeader from "../../../common/BasicInfoCardHeader";
import BasicFooterButton from "../../../common/BasicFooterButton";
import { HumanResourceType } from "../../../../types/basicInfo/HumanResourceType";
import { EmpType } from "../../../../types/EmpType";
import {
  getEvaluatorList,
  getHumanResource,
  postHumanResource,
} from "./api/Api";
import { EvaluatorListType } from "types/basicInfo/EvaluatorListType";
import {
  isCardEditing,
  isInputEditing,
  selectRequiredItem,
} from "pages/common/LayoutFunction";
import { IsCardEditable } from "../../../common/IsEditable";
import {
  handleGetResponse,
  handlePostResponse,
} from "components/handleResponse";
import { error } from "console";
import { SubmitHandler, useForm } from "react-hook-form";
import ToastMsg from "components/ToastMsg";

const HumanrRsource = (emp: EmpType) => {
  /** 状態管理パラメーター */
  // 編集状態を管理するフラグ（true：編集中、false：非編集中）
  const [isEdit, setIsEdit] = useState(false);
  // リロードが必要かを管理するフラグ（true：必要、false：不必要）
  const [isReload, setIsReload] = useState<boolean>(true);
  // データを保存したかを管理するフラグ
  const [isSave, setIsSave] = useState<boolean>(false);

  /** DB値 */
  const [humanResourceData, setHumanResourceData] =
    useState<HumanResourceType>();
  const [evaluatorList, setEvaluatorList] = useState<EvaluatorListType[]>([]); // 評価者リスト

  /** 画面表示項目 */
  const [inputPrimaryEvaluatorEmpDataId, setPrimaryEvaluatorEmpDataId] =
    useState<number | null>(null); // 一次評価者社員データID
  const [inputSecondaryEvaluatorEmpDataId, setSecondaryEvaluatorEmpDataId] =
    useState<number | null>(null); // 二次評価者社員データID
  const [inputRemoteWorkStatus, setRemoteWorkStatus] = useState(0); //リモートメイン
  const [inputContractTypeDetails, setContractTypeDetails] = useState(""); //契約形態_補足
  const [inputHiringType, setHiringType] = useState(0); //新卒
  const [inputEmployeeAppointmentType, setEmployeeAppointmentType] =
    useState(0); //社員登用
  const [inputBackbone, setBackbone] = useState(""); //バックボーン
  const [inputELearningAverageResponseTime, setELearningAverageTime] =
    useState(""); //平均回答時間
  const [inputReferralNum, setReferralNum] = useState<number | null>(null); //リファラル数
  const [inputHasSideJob, setHasSideJob] = useState(0); //副業
  const [inputPunishment, setPunishment] = useState(""); //懲罰
  const [inputCompliance, setCompliance] = useState(""); //コンプラ

  /**
   * DB値の反映処理
   *
   * @param dbHumanResource 人事情報
   */
  const dbDataToInputValues = (dbHumanResource: HumanResourceType) => {
    //入力項目に展開
    setPrimaryEvaluatorEmpDataId(dbHumanResource.primaryEvaluatorEmpDataId); // 選択された一次次評価者社員データID
    setSecondaryEvaluatorEmpDataId(dbHumanResource.secondaryEvaluatorEmpDataId); // 選択された二次評価者社員データID
    setRemoteWorkStatus(dbHumanResource.remoteWorkStatus); //リモートメイン
    setContractTypeDetails(dbHumanResource.contractTypeDetails ?? "");
    setHiringType(dbHumanResource.hiringType ?? "");
    setEmployeeAppointmentType(dbHumanResource.employeeAppointmentType ?? "");
    setBackbone(dbHumanResource.backbone ?? "");
    setELearningAverageTime(
      String(dbHumanResource.elearningAverageResponseTime ?? "")
    ); //平均回答時間
    setReferralNum(dbHumanResource.referralNum ?? null);
    setHasSideJob(dbHumanResource.hasSideJob ?? "");
    setPunishment(dbHumanResource.punishment ?? "");
    setCompliance(dbHumanResource.compliance ?? "");
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    // リロード対象の場合、取得APIを呼び出す
    if (isReload) {
      (async () => {
        await handleGetResponse(getHumanResource(emp.empDataId)).then(
          (data) => {
            setHumanResourceData(data);
            dbDataToInputValues(data);
            setIsReload(false);
            setIsSave(false);
          }
        );
      })();
    }
  }, [isReload]);

  /**
   * 評価者リストを取得
   */
  useEffect(() => {
    (async () => {
      await handleGetResponse(getEvaluatorList(emp.empDataId)).then((data) => {
        setEvaluatorList(data);
      });
    })();
  }, []);

  /**
   * 「キャンセル」ボタンクリックハンドラー
   */
  const onClickInputClearHandler = () => {
    if (humanResourceData) {
      dbDataToInputValues(humanResourceData);
    }
    reset(); // バリデーションメッセージのリセット
    setIsEdit(false);
  };

  /**
   * 編集アイコンクリックハンドラー
   */
  const onClickEditHandler = () => {
    setIsEdit(true);
  };

  //モーダルダイアログの開閉を管理するための関数
  const handleClose = () => {
    reset();
  };

  // バリデーション用のオブジェクト、メソッドを定義
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<HumanResourceType>();

  /**
   * 「保存」ボタンクリックハンドラー
   */
  const onClickButtonSaveHandler: SubmitHandler<HumanResourceType> = () => {
    if (humanResourceData) {
      // 更新リクエスト時のパラメータを設定する
      const humanResource: HumanResourceType = {
        dataId: humanResourceData?.dataId,
        empDataId: emp.empDataId,
        primaryEvaluatorEmpDataId: inputPrimaryEvaluatorEmpDataId,
        secondaryEvaluatorEmpDataId: inputSecondaryEvaluatorEmpDataId,
        remoteWorkStatus: inputRemoteWorkStatus,
        contractTypeDetails: inputContractTypeDetails,
        hiringType: inputHiringType,
        employeeAppointmentType: inputEmployeeAppointmentType,
        backbone: inputBackbone,
        elearningAverageResponseTime:
          Number(inputELearningAverageResponseTime) || null,
        referralNum: inputReferralNum,
        hasSideJob: inputHasSideJob,
        punishment: inputPunishment,
        compliance: inputCompliance,
        updatedAt: humanResourceData.updatedAt,
      };

      // 人事情報更新API
      (async () => {
        await handlePostResponse(postHumanResource(humanResource))
          .then((data) => {
            setIsReload(true);
            setIsSave(true);
          })
          .catch((error) => {
            console.error("POSTリクエストでエラーが発生しました。");
          });
      })();
    }
    setIsEdit(false);
  };

  return (
    <>
      {humanResourceData && evaluatorList && (
        <Box sx={isCardEditing(isEdit)}>
          <BasicInfoCardHeader
            headerTitle="人事"
            onClickEditHandler={() => onClickEditHandler()}
            iconType="def"
            editable={IsCardEditable(emp.isEmployed)}
          />
          <CardContent>
            <Box
              sx={{ mt: 2 }}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <FormControl>
                <Autocomplete
                  size="small"
                  readOnly={!isEdit}
                  sx={[{ width: "225px" }, isInputEditing(isEdit)]}
                  options={evaluatorList}
                  getOptionLabel={(option) => option.employeeName}
                  renderInput={(params) => (
                    <TextField {...params} label="一次評価者" />
                  )}
                  value={
                    evaluatorList.find(
                      (evaluator) =>
                        evaluator.empDataId === inputPrimaryEvaluatorEmpDataId
                    ) ?? null
                  }
                  onChange={(event, value) => {
                    setPrimaryEvaluatorEmpDataId(
                      value ? value.empDataId : null
                    );
                  }}
                />
              </FormControl>
              <FormControl>
                <Autocomplete
                  size="small"
                  readOnly={!isEdit}
                  sx={[{ ml: 2, width: "225px" }, isInputEditing(isEdit)]}
                  options={evaluatorList}
                  getOptionLabel={(option) => option.employeeName}
                  renderInput={(params) => (
                    <TextField {...params} label="二次評価者" />
                  )}
                  value={
                    evaluatorList.find(
                      (evaluator) =>
                        evaluator.empDataId === inputSecondaryEvaluatorEmpDataId
                    ) ?? null
                  }
                  onChange={(event, value) => {
                    setSecondaryEvaluatorEmpDataId(
                      value ? value.empDataId : null
                    );
                  }}
                />
              </FormControl>
            </Box>

            <Box
              sx={{ mt: 2 }}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <FormControl>
                <InputLabel size="small" id="isRemoteWork">
                  リモートメイン
                </InputLabel>
                <Select
                  size="small"
                  labelId="isRemoteWork"
                  id="isRemoteWork-select"
                  label="リモートメイン"
                  inputProps={{ readOnly: !isEdit }}
                  sx={[
                    { display: "flex", width: "225px" },
                    isInputEditing(isEdit),
                  ]}
                  value={inputRemoteWorkStatus}
                  onChange={(e) => {
                    if (typeof e.target.value === "string") {
                      setRemoteWorkStatus(Number(e.target.value));
                    }
                  }}
                >
                  <MenuItem value={"1"}>リモートワーク</MenuItem>
                  <MenuItem value={"0"}>出社</MenuItem>
                </Select>
              </FormControl>

              <TextField
                size="small"
                label="契約形態_補足"
                defaultValue={humanResourceData.contractTypeDetails}
                InputProps={{ readOnly: !isEdit }}
                sx={[
                  { ml: 2, display: "flex", width: "225px" },
                  isInputEditing(isEdit),
                ]}
                value={inputContractTypeDetails}
                onChange={(e) => {
                  setContractTypeDetails(e.target.value);
                }}
              />
            </Box>

            <Box sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel
                  size="small"
                  id="isNewGraduate"
                  required
                  sx={selectRequiredItem}
                >
                  採用区分
                </InputLabel>
                <Select
                  size="small"
                  labelId="isNewGraduate"
                  id="isNewGraduate-select"
                  label="採用区分"
                  sx={[{ width: "225px" }, isInputEditing(isEdit)]}
                  value={inputHiringType}
                  // バリデーションチェック
                  {...register("hiringType", {
                    required: {
                      value: true,
                      message: "採用区分は必須項目です。",
                    },
                  })}
                  error={"hiringType" in errors}
                  onChange={(e) => {
                    setHiringType(Number(e.target.value));
                  }}
                  inputProps={{ readOnly: !isEdit }}
                >
                  <MenuItem value={-1}>-</MenuItem>
                  <MenuItem value={0}>新卒</MenuItem>
                  <MenuItem value={1}>中途</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{ mt: 2 }}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <FormControl>
                <InputLabel
                  size="small"
                  id="isRegularEmployee"
                  required
                  sx={selectRequiredItem}
                >
                  社員登用
                </InputLabel>
                <Select
                  size="small"
                  labelId="isRegularEmployee"
                  id="isRegularEmployee-select"
                  label="社員登用"
                  sx={[
                    { display: "flex", width: "225px" },
                    isInputEditing(isEdit),
                  ]}
                  value={inputEmployeeAppointmentType}
                  // バリデーションチェック
                  {...register("employeeAppointmentType", {
                    required: {
                      value: true,
                      message: "社員登用は必須項目です。",
                    },
                  })}
                  error={"employeeAppointmentType" in errors}
                  onChange={(e) => {
                    setEmployeeAppointmentType(Number(e.target.value));
                  }}
                  inputProps={{ readOnly: !isEdit }}
                >
                  <MenuItem value={1}>YES</MenuItem>
                  <MenuItem value={0}>NO</MenuItem>
                </Select>
              </FormControl>

              <TextField
                size="small"
                label="（バックボーン）"
                defaultValue={humanResourceData.backbone}
                InputProps={{ readOnly: !isEdit }}
                sx={[
                  { ml: 2, display: "flex", width: "225px" },
                  isInputEditing(isEdit),
                ]}
                value={inputBackbone}
                onChange={(e) => {
                  setBackbone(e.target.value);
                }}
              />
            </Box>

            <Box
              sx={{ mt: 2 }}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <TextField
                size="small"
                label="e-Learning平均回答時間"
                type="number"
                defaultValue={humanResourceData.elearningAverageResponseTime}
                InputProps={{ readOnly: !isEdit }}
                sx={[
                  { display: "flex", width: "225px" },
                  isInputEditing(isEdit),
                ]}
                value={inputELearningAverageResponseTime}
                {...register("elearningAverageResponseTime", {
                  pattern: {
                    value: /^(0|\d{1,2}(\.\d{0,1})?)$/,
                    message:
                      "e-Learning平均回答時間は整数2桁、小数1桁で入力してください",
                  },
                })}
                error={"elearningAverageResponseTime" in errors}
                onChange={(e) => {
                  setELearningAverageTime(e.target.value);
                }}
              />
              <TextField
                size="small"
                label="リファラル数"
                type="number"
                value={inputReferralNum ?? ""}
                InputProps={{ readOnly: !isEdit }}
                sx={[
                  { ml: 2, display: "flex", width: "225px" },
                  isInputEditing(isEdit),
                ]}
                {...register("referralNum", {
                  pattern: {
                    value: /^(\d{0,4})$/,
                    message:
                      "リファラル数は0以上4桁以下の整数で入力してください",
                  },
                })}
                error={"referralNum" in errors}
                onChange={(e) => {
                  setReferralNum(
                    e.target.value === "" ? null : Number(e.target.value)
                  );
                }}
              />
            </Box>

            <Box
              sx={{ mt: 2 }}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <FormControl>
                <InputLabel
                  size="small"
                  id="hasSideJob"
                  required
                  sx={selectRequiredItem}
                >
                  （副業）
                </InputLabel>
                <Select
                  size="small"
                  labelId="hasSideJob"
                  id="hasSideJob-select"
                  label="（副業）"
                  inputProps={{ readOnly: !isEdit }}
                  sx={[
                    { display: "flex", width: "225px" },
                    isInputEditing(isEdit),
                  ]}
                  value={inputHasSideJob}
                  // バリデーションチェック
                  {...register("hasSideJob", {
                    required: {
                      value: true,
                      message: "（副業）は必須項目です。",
                    },
                  })}
                  error={"hasSideJob" in errors}
                  onChange={(e) => {
                    setHasSideJob(Number(e.target.value));
                  }}
                >
                  <MenuItem value={1}>YES</MenuItem>
                  <MenuItem value={0}>NO</MenuItem>
                </Select>
              </FormControl>

              <TextField
                size="small"
                label="（懲罰）"
                defaultValue={humanResourceData.punishment}
                InputProps={{ readOnly: !isEdit }}
                sx={[{ ml: 2, display: "flex" }, isInputEditing(isEdit)]}
                value={inputPunishment}
                onChange={(e) => {
                  setPunishment(e.target.value);
                }}
              />
            </Box>

            {(errors.hiringType ||
              errors.employeeAppointmentType ||
              errors.referralNum ||
              errors.elearningAverageResponseTime ||
              errors.hasSideJob) && (
              // ダイアログの表示
              <Dialog open={true} onClose={handleClose}>
                <DialogTitle>エラー</DialogTitle>
                <DialogContent>
                  {errors.hiringType && (
                    <DialogContentText>
                      {errors.hiringType.message}
                    </DialogContentText>
                  )}
                  {errors.employeeAppointmentType && (
                    <DialogContentText>
                      {errors.employeeAppointmentType.message}
                    </DialogContentText>
                  )}
                  {errors.referralNum && (
                    <DialogContentText>
                      {errors.referralNum.message}
                    </DialogContentText>
                  )}
                  {errors.elearningAverageResponseTime && (
                    <DialogContentText>
                      {errors.elearningAverageResponseTime.message}
                    </DialogContentText>
                  )}
                  {errors.hasSideJob && (
                    <DialogContentText>
                      {errors.hasSideJob.message}
                    </DialogContentText>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                    閉じる
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            <Box sx={{ mt: 2 }}>
              <TextField
                size="small"
                label="（コンプラ）"
                defaultValue={humanResourceData.compliance}
                InputProps={{ readOnly: !isEdit }}
                sx={[{ width: "225px" }, isInputEditing(isEdit)]}
                value={inputCompliance}
                onChange={(e) => {
                  setCompliance(e.target.value);
                }}
              />

              <BasicFooterButton
                isEditing={isEdit}
                mtop={-5}
                mright={1}
                onClickSave={handleSubmit(onClickButtonSaveHandler)}
                onClickCancel={onClickInputClearHandler}
              />
              <ToastMsg isDisp={isSave} isSuccess={true} />
            </Box>
          </CardContent>
        </Box>
      )}
    </>
  );
};

export default HumanrRsource;
