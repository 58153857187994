import { useState, useEffect } from "react";

export interface ToastMsgIf {
  isDisp: boolean;
  isSuccess: boolean;
  msg?: string;
}

const ToastMsg = (props: ToastMsgIf) => {
  // 表示を管理するフラグ
  const [visible, setVisible] = useState<boolean>(false);

  /**
   * トーストメッセージ表示時間管理
   */
  useEffect(() => {
    if (props.isDisp) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 5000); // 5秒表示
    }
  }, [props.isDisp]);

  return (
    <>
      {visible && (
        <div
          style={{
            position: "fixed",
            top: "15px",
            right: "15px",
            zIndex: "10", // 一番上に表示させるため
            textAlign: "center",
            height: "auto",
            width: `${props.isSuccess ? "230px" : "auto"}`,
            padding: "10px",
            margin: "5px auto",
            borderRadius: "20px",
            border: `2px solid ${props.isSuccess ? "#66CC66" : "#FF0000"}`,
            backgroundColor: "white",
          }}
        >
          {props.isSuccess ? (
            <>
              <h1 style={{ color: "#32CD32", margin: "0" }}>Success</h1>
              <p style={{ color: "#32CD32", margin: "0", fontSize: "20px" }}>
                保存しました
              </p>
            </>
          ) : (
            <>
              <h1 style={{ color: "#FF0000", margin: "0" }}>Error</h1>
              <p style={{ color: "#FF0000", margin: "0", fontSize: "20px" }}>
                {props.msg}
              </p>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ToastMsg;
