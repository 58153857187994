import { useEffect, useState } from "react";
import Header from "../../components/Header";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { PermissionAndOrganizationType } from "types/concurrentlyPositionion/PermissionAndOrganizationType";
import { PermissionLevelCode } from "../../constants/Enum";
import { checkAuthenticated } from "auth/checkAuthenticated";

const ConcurrentlyPositionion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { empDataId } = useParams<string>();
  const [organizationData, setOrganization] =
    useState<PermissionAndOrganizationType[]>();

  useEffect(() => {
    // 認証処理を実施
    checkAuthenticated(navigate, location).then((loginUserInfo) => {
      if (loginUserInfo) {
        // 認証されている場合、所属組織情報を設定する
        const convertedOrganizationData: PermissionAndOrganizationType[] =
          location.state.loginUserInfo.permissionDtoList.map(
            (item: PermissionAndOrganizationType) => ({
              empDataId: item.empDataId,
              permissionCode: item.permissionCode,
              permissionLevelCode: item.permissionLevelCode,
              permissionRange: item.permissionRange,
              permissionLevelType: item.permissionLevelType,
              exclusionCode: item.exclusionCode,
              organizationCode: item.organizationCode,
              organizationLevel: item.organizationLevel,
              organizationName: item.organizationName,
            })
          );
        setOrganization(convertedOrganizationData);
      }
    });
  }, []);

  const onClickHandler = (organizationData: PermissionAndOrganizationType) => {
    if (organizationData.permissionLevelCode === PermissionLevelCode.MYSELF) {
      navigate(`/talentdetail/${empDataId}`, {
        state: {
          loginUserInfo: location.state.loginUserInfo,
          currentOrganization: organizationData,
          concurrentScreenFlag: true,
        },
      });
    } else {
      navigate(`/employeeSearch`, {
        state: {
          loginUserInfo: location.state.loginUserInfo,
          currentOrganization: organizationData,
          concurrentScreenFlag: true,
        },
      });
    }
  };

  return (
    <>
      <Header
        title="兼務一覧"
        username={location?.state?.loginUserInfo.fullName}
      />
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <TableContainer sx={{ ml: 30, mt: 5, mr: 30 }}>
          <Table aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#EAF3FF" }}>
              <TableRow>
                <TableCell>部署名</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizationData?.map((organizationData, index) => (
                <TableRow
                  key={index}
                  hover
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => onClickHandler(organizationData)}
                >
                  <TableCell>{organizationData.organizationName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ConcurrentlyPositionion;
