import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";

type basicFooterButtonType = {
  isEditing: boolean;
  mtop?: number;
  mright?: number;
  onClickSave: () => void;
  onClickCancel: () => void;
};

/**
 * 「保存」「キャンセル」ボタンコンポーネント
 *
 * @param isEditing 編集中判定フラグ
 * @param mtop マージントップ指定（デフォルト：1）
 * @param mright マージンライト指定（デフォルト：0）
 * @param onClickSave 保存実行時に呼び出される関数
 * @param onClickCancel キャンセル実行時に呼び出される関数
 * @returns コンポーネント
 */
const BasicFooterButton = ({
  isEditing,
  mtop = 1,
  mright = 0,
  onClickSave,
  onClickCancel,
}: basicFooterButtonType) => {
  // ラベル定義
  const [LABEL_SAVE, LABEL_CANCEL] = ["保存", "キャンセル"];
  // ダイアログ文言定義
  const [DIALOG_TITLE, DIALOG_CONFIRM_MSG] = ["確認", "保存しますか？"];
  // ダイアログアクション定数
  const [ACTION_SAVE, ACTION_CANCEL] = ["save", "cancel"];

  // ダイアログ開閉フラグ
  const [open, setOpen] = useState(false);

  /**
   * ダイアログを開く処理
   */
  const handleClickOpen = () => {
    // ダイアログを開く
    setOpen(true);
  };

  /**
   * ダイアログを閉じる処理
   *
   * @param action 実行アクション（保存/キャンセル）
   */
  const handleClose = (action: string) => {
    if (action === ACTION_SAVE) {
      // 保存時のイベントを実行
      onClickSave();
    } else if (action === ACTION_CANCEL) {
      // キャンセル時のイベントを実行
      onClickCancel();
    }
    // ダイアログを閉じる
    setOpen(false);
  };

  return (
    <>
      {isEditing ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <Button
            variant="contained"
            sx={{ ml: "auto", mt: mtop, display: "flex" }}
            onClick={handleClickOpen}
          >
            {LABEL_SAVE}
          </Button>
          <Button
            variant="contained"
            sx={{
              ml: 1,
              mt: mtop,
              mr: mright,
              display: "flex",
              backgroundColor: "#8D8D8D",
              "&:hover": { backgroundColor: "#7D7D7D" },
            }}
            onClick={onClickCancel}
          >
            {LABEL_CANCEL}
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{DIALOG_TITLE}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {DIALOG_CONFIRM_MSG}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose(ACTION_CANCEL)}>
                {LABEL_CANCEL}
              </Button>
              <Button onClick={() => handleClose(ACTION_SAVE)}>
                {LABEL_SAVE}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      ) : (
        <Button
          variant="text"
          disabled
          sx={{
            mt: mtop,
            height: "36px",
            border: "none",
            background: "transparent",
          }}
        ></Button>
      )}
    </>
  );
};
export default BasicFooterButton;
