import { Box, Card, CardContent, CardHeader } from "@mui/material";
import Directional from "./contents/Directional";
import { EmpType } from "../../../types/EmpType";
import SeInspection from "./contents/SeInspection";

const Challenging = (emp: EmpType) => {
  const cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    width: "320px",
    variant: "outlined",
  };

  return (
    <Card style={cardStyle} sx={{ border: 1, borderColor: "#FBB161" }}>
      <CardHeader
        title="やりがい"
        sx={{ backgroundColor: "#FBB161", color: "white" }}
      />
      <CardContent>
        <div>
          <Box>
            <Card sx={{ ml: -0.5, mt: 2, width: "300px", height: "470px" }}>
              <Directional
                empDataId={emp.empDataId}
                isEmployed={emp.isEmployed}
              />
            </Card>
            <Card sx={{ ml: -0.5, mt: 2, width: "300px", height: "200px" }}>
              <SeInspection
                empDataId={emp.empDataId}
                isEmployed={emp.isEmployed}
              />
            </Card>
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};

export default Challenging;
