import { useEffect, useState } from "react";
import BasicInfoCardHeader from "pages/common/BasicInfoCardHeader";
import { Box, CardContent, Grid, TextField } from "@mui/material";
import { FfsResultType } from "types/basicInfo/FfsResultType";
import { EmpType } from "types/EmpType";
import { getFfs } from "./api/Api";
import { isInputEditing, isRequiredItem } from "pages/common/LayoutFunction";
import { handleGetResponse } from "components/handleResponse";

const Ffs = (emp: EmpType) => {
  /** DB値 */
  const [ffsResultData, setFfsResult] = useState<FfsResultType>();

  /**
   * 初期処理
   */
  useEffect(() => {
    (async () => {
      await handleGetResponse(getFfs(emp.empDataId)).then((data) => {
        setFfsResult(data);
      });
    })();
  }, []);

  return (
    <div>
      {ffsResultData && (
        <>
          <BasicInfoCardHeader
            headerTitle="FFS"
            iconType="def"
            editable={false}
          />
          <CardContent>
            <Box>
              <TextField
                size="small"
                label="91分類"
                InputProps={{ readOnly: true }}
                sx={isInputEditing(false)}
                required
                InputLabelProps={{ component: isRequiredItem }}
                value={ffsResultData.ffsTypeCode}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    label="凝縮性"
                    type="number"
                    InputProps={{ readOnly: true }}
                    sx={isInputEditing(false)}
                    required
                    InputLabelProps={{ component: isRequiredItem }}
                    value={ffsResultData.condensabilityScore}
                  />
                </Grid>
                <Grid item xs={2} sx={{ ml: 1.5 }}>
                  <TextField
                    size="small"
                    label="受容性"
                    type="number"
                    InputProps={{ readOnly: true }}
                    sx={isInputEditing(false)}
                    required
                    InputLabelProps={{ component: isRequiredItem }}
                    value={ffsResultData.receptivityScore}
                  />
                </Grid>
                <Grid item xs={2} sx={{ ml: 1.5 }}>
                  <TextField
                    size="small"
                    label="弁別性"
                    type="number"
                    InputProps={{ readOnly: true }}
                    sx={isInputEditing(false)}
                    required
                    InputLabelProps={{ component: isRequiredItem }}
                    value={ffsResultData.distinguishabilityScore}
                  />
                </Grid>
                <Grid item xs={2} sx={{ ml: 1.5 }}>
                  <TextField
                    size="small"
                    label="拡散性"
                    type="number"
                    InputProps={{ readOnly: true }}
                    sx={isInputEditing(false)}
                    required
                    InputLabelProps={{ component: isRequiredItem }}
                    value={ffsResultData.diffusivityScore}
                  />
                </Grid>
                <Grid item xs={2} sx={{ ml: 1.5 }}>
                  <TextField
                    size="small"
                    label="保全性"
                    type="number"
                    InputProps={{ readOnly: true }}
                    sx={isInputEditing(false)}
                    required
                    InputLabelProps={{ component: isRequiredItem }}
                    value={ffsResultData.maintainabilityScore}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 3 }}>
              <TextField
                size="small"
                label="ストレス値"
                type="number"
                InputProps={{ readOnly: true }}
                sx={isInputEditing(false)}
                required
                InputLabelProps={{ component: isRequiredItem }}
                value={ffsResultData.stressValue}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={3}>
                  <TextField
                    size="small"
                    label="4分類"
                    InputProps={{ readOnly: true }}
                    sx={isInputEditing(false)}
                    required
                    InputLabelProps={{ component: isRequiredItem }}
                    value={ffsResultData.fourClassifications}
                  />
                </Grid>
                <Grid item xs={3} sx={{ ml: 1.5 }}>
                  <TextField
                    size="small"
                    label="信用スコア"
                    type="number"
                    InputProps={{ readOnly: true }}
                    sx={isInputEditing(false)}
                    required
                    InputLabelProps={{ component: isRequiredItem }}
                    value={ffsResultData.creditScore}
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </>
      )}
    </div>
  );
};

export default Ffs;
