import axios from "axios";
import { getCurrentIdToken } from "auth/checkAuthenticated";

// Axiosインスタンスの生成
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_HOST,
    timeout: 10000,
});

// リクエストインタセプタの設定
apiClient.interceptors.request.use(async (config) => {
    const idToken = await getCurrentIdToken();
    if (idToken) {
        config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default apiClient;