import { LineChart } from "@mui/x-charts/LineChart";
import Box from "@mui/material/Box";
import { ViewIncomeType } from "../types/summary/ViewIncomType";
import LineLabel from "./LineLabel";

// componentの定義
const IncomeLineGraph = (viewIncomeData: ViewIncomeType) => {
  return (
    <>
      {viewIncomeData && (
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
            display: "flex",
            ml: 2,
          }}
        >
          {viewIncomeData.arrDate.length > 0 ? (
            <>
              <LineChart
                xAxis={[
                  {
                    id: "Years",
                    data: viewIncomeData.arrDate,
                    scaleType: "time",
                    valueFormatter: (date) => date.getFullYear().toString(),
                  },
                ]}
                yAxis={[{ valueFormatter: (v) => `${v / 10000}万 ` }]}
                series={[
                  { curve: "linear", data: viewIncomeData.annualIncome },
                  {
                    curve: "linear",
                    data: viewIncomeData.futureIncomeEmployee,
                  },
                  { curve: "linear", data: viewIncomeData.futureIncomeManager },
                  { curve: "linear", data: viewIncomeData.previousIncome },
                ]}
                // widthによってスケール数が変わる
                width={650}
                height={550}
              />
              <Box>
                <LineLabel colorCode={"#02B2AF"} labelName={"年収"} />
                <div></div>
                <LineLabel
                  colorCode={"#2E96FF"}
                  labelName={"五年後年収（本人）"}
                />
                <div></div>
                <LineLabel
                  colorCode={"#B800D8"}
                  labelName={"五年後年収（上長）"}
                />
                <div></div>
                <LineLabel colorCode={"#7B1FA2"} labelName={"前職年収"} />
                <div></div>
              </Box>
            </>
          ) : (
            <Box sx={{ width: 800, height: 550 }}></Box>
          )}
        </Box>
      )}
    </>
  );
};

export default IncomeLineGraph;
