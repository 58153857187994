import { PermissionLevelCode } from "../../constants/Enum";
import { useLocation } from "react-router-dom";

/**
 * カードの編集・鍵アイコンの表示、非表示を制御する関数
 *
 * @returns boolean
 *
 */
export const IsCardEditable = (isEmployed: boolean) => {
  const location = useLocation();
  // 選択された組織の権限レベルを取得
  const permissionLevelCode =
    location.state.currentOrganization.permissionLevelCode;
  /**
   * 権限レベルをもとに編集可否を判定
   * 権限レベルK7：falseを返す
   */
  if (
    permissionLevelCode ===
      PermissionLevelCode.ALL_EMPLOYEES_EXCLUDING_OWN_DIVISION ||
    !isEmployed
  ) {
    return false;
  } else {
    return true;
  }
};
