import { Semester } from "constants/Enum";

export const getFiscalYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    return currentMonth >= 4 ? currentYear : currentYear - 1;
}

export const convertFiscalYear = (year: number = 2021, month: number = 4) => {
    return month >= 4 ? year : year - 1;
}

export const getSemester = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;

    return 4 <= currentMonth && currentMonth <= 9 ? Semester.FIRST : Semester.SECOND;
}