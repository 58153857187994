/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": `${process.env.REACT_APP_IDENTITY_POOL_ID}`,
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": `${process.env.REACT_APP_USER_POOLS_ID}`,
    "aws_user_pools_web_client_id": `${process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID}`,
    "oauth": {
        "domain": "tms91d0e6a5-91d0e6a5-dev.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": `${process.env.REACT_APP_CLIENT_HOST}/`,
        "redirectSignOut": `${process.env.REACT_APP_CLIENT_HOST}/`,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
