import { KeyboardEventHandler, useEffect, useState } from "react";
import Header from "../../components/Header";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { searchEmployee } from "./api/Api";
import React from "react";
import { MEmployeesType } from "../../types/MEmployeesType";
import { setErrMsgCallback, setNavigateCallback } from "../../lib/commonAxios";
import ErrorMsg, { ErrorMsgIf } from "../../components/ErrorMsg";
import { checkAuthenticated } from "auth/checkAuthenticated";
import { handleGetResponse } from "components/handleResponse";
import { getDepartmentNames } from "pages/employeeSearch/api/Api";

const EmployeeSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // 部署名が空文字かを判定するフラグ
  const [emptyDepartmentName, setEmptyDepartmentName] = useState<boolean>(true);

  // 社員番号
  const [searchEmpId, setSearchEmpId] = useState("");
  // 従業員名
  const [searchName, setSearchName] = useState("");
  // 従業員名（かな）
  const [searchNameKana, setSearchNameKana] = useState("");
  //部署名リスト
  const [departmentNameOptions, setDepartmentNameOptions] = useState<String[]>(
    []
  );
  // 部署名
  const [searchDepartmentName, setSearchDepartmentName] = useState("");
  // 社員一覧
  const [mEmployee, setMEmployee] = useState<MEmployeesType[]>([]);

  // ページネーション
  const [pg, setPage] = React.useState(0);
  const [rpg, setRowsPerPage] = React.useState(20);

  // エラーメッセージ設定
  const [errMsg, setErrMsg] = useState<ErrorMsgIf>({
    msg: "",
    isDisp: false,
  });
  // 退職済みテキスト
  const retired = " (※退職済み)";

  // apiで500番台のエラーが起きた時、エラー画面に遷移する
  useEffect(() => {
    setNavigateCallback(navigate);
  }, [navigate]);

  // apiで400番台のエラーが起きた時、エラーメッセージを表示する
  useEffect(() => {
    setErrMsgCallback(setErrMsg);
  }, [setErrMsg]);

  // 初期表示
  useEffect(() => {
    // 認証処理を実施
    checkAuthenticated(navigate, location);
    // 部署名一覧取得
    (async () => {
      await handleGetResponse(getDepartmentNames()).then((data) => {
        setDepartmentNameOptions(data);
      });
    })();
  }, []);

  // 検索
  const onClickSearchHandler = () => {
    setErrMsg({
      msg: "",
      isDisp: false,
    });
    if (
      searchEmpId === "" &&
      searchName === "" &&
      searchNameKana === "" &&
      searchDepartmentName === ""
    ) {
      setErrMsg({
        msg: "少なくとも1つの検索項目を入力してください。",
        isDisp: true,
      });
      return;
    }
    (async () => {
      const { data, status } = await searchEmployee(
        // 検索パラメータ
        searchEmpId,
        searchName,
        searchNameKana,
        searchDepartmentName,
        // ログインユーザ情報
        location.state.currentOrganization.organizationCode,
        location.state.currentOrganization.permissionCode,
        location.state.currentOrganization.permissionLevelCode,
        location.state.currentOrganization.exclusionCode,
        location.state.loginUserInfo.dataId
      );
      // 正常値が返却された時に行なう処理
      if (status === 200) {
        setMEmployee(data);
      } else if (status === 400) {
        // 400エラーの時に行なう処理
      } else if (status === 500) {
        // 500エラーの時に行なう処理
      }
    })();
  };

  // クリア
  const onClickClearHandler = () => {
    setSearchEmpId("");
    setSearchName("");
    setSearchNameKana("");
    setSearchDepartmentName("");
  };

  // テーブル行クリック
  const onClickTalentDetailHandler = (dataId: number) => {
    navigate(`/talentdetail/${dataId}`, {
      state: {
        loginUserInfo: location.state.loginUserInfo,
        currentOrganization: location.state.currentOrganization,
        concurrentScreenFlag: false,
      },
    });
  };

  // ページネーション
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  /**
   * テキストフィールド内でエンターを押したときに検索を実行する
   * @param event
   */
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Enter" && emptyDepartmentName) {
      onClickSearchHandler();
    }
  };

  return (
    <>
      <Header
        title="タレントマネジメント検索一覧"
        username={location?.state?.loginUserInfo.fullName}
      />
      <ErrorMsg msg={errMsg.msg} isDisp={errMsg.isDisp} />
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <TextField
          id="searchEmpId"
          label="社員番号"
          value={searchEmpId}
          sx={{ ml: 5, mt: 5, width: 300 }}
          onChange={(e) => {
            setSearchEmpId(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
        <TextField
          id="searchName"
          label="名前"
          value={searchName}
          sx={{ ml: 2, mt: 5, width: 300 }}
          onChange={(e) => {
            setSearchName(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
        <TextField
          id="searchNameKana"
          label="名前（かな）"
          value={searchNameKana}
          sx={{ ml: 2, mt: 5, width: 300 }}
          onChange={(e) => {
            setSearchNameKana(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
        <Autocomplete
          sx={{ ml: 2, mt: 5, width: 300 }}
          options={departmentNameOptions}
          value={searchDepartmentName}
          renderInput={(params) => <TextField {...params} label="部署名" />}
          onChange={(event: any, value: String | null) => {
            if (value !== null && value !== "") {
              setSearchDepartmentName(String(value));
              setEmptyDepartmentName(true);
            } else {
              setEmptyDepartmentName(false);
            }
          }}
          onKeyDown={handleKeyDown}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Button
          variant="contained"
          sx={{ ml: 5, mt: 5, display: "flex" }}
          onClick={() => onClickSearchHandler()}
        >
          検索
        </Button>
        <Button
          variant="contained"
          sx={{
            ml: 5,
            mt: 5,
            display: "flex",
            backgroundColor: "#8D8D8D",
            "&:hover": { backgroundColor: "#7D7D7D" },
          }}
          onClick={() => onClickClearHandler()}
        >
          クリア
        </Button>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <TableContainer sx={{ ml: 5, mt: 5, mr: 5 }}>
          <Table aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#EAF3FF" }}>
              <TableRow>
                <TableCell>社員番号</TableCell>
                <TableCell align="left">名前</TableCell>
                <TableCell align="left">部署名</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mEmployee.slice(pg * rpg, pg * rpg + rpg).map((talent) => (
                <TableRow
                  hover
                  key={talent.dataId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => onClickTalentDetailHandler(talent.dataId)}
                >
                  <TableCell component="th" scope="row">
                    {talent.empNo}
                  </TableCell>
                  <TableCell align="left">{`${talent.employeeName}${
                    talent.employed ? "" : retired
                  }`}</TableCell>
                  <TableCell align="left">{talent.departmentName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={mEmployee.length}
            rowsPerPage={rpg}
            page={pg}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </>
  );
};

export default EmployeeSearch;
