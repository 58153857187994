import { DirectivityType } from "types/challenging/DirectivityType";
import apiClient from "components/apiClient";
import { CareerCourseMasterType } from "types/master/CareerCourseMasterType";

/**
 * 指向性情報取得API
 * @param empDataId 社員データID
 * @returns 指向性情報
 */
export const getDirectiviy = async (empDataId: number) => {
  return await apiClient.get<DirectivityType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/directivity`
  );
};

/**
 * 指向性情報更新API
 * @param data 指向性情報DTO
 * @returns 指向性情報
 */
export const postDirectiviy = async (data: DirectivityType) => {
  return await apiClient.post(
    `${process.env.REACT_APP_HOST}/employees/directivity`,
    data
  );
};

/**
 * キャリアコースマスタ情報取得API
 *
 * @returns キャリアコースマスタ情報
 */
export const getCareerCourseMaster = async () => {
  return await apiClient.get<CareerCourseMasterType[]>(
    `${process.env.REACT_APP_HOST}/master/getCareerCourseMaster`
  );
};
