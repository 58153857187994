import { useEffect, useState } from "react";
import BasicInfoCardHeader from "../../../common/BasicInfoCardHeader";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { EmpType } from "../../../../types/EmpType";
import { HiringDetailType } from "../../../../types/basicInfo/HiringDetailType";
import BasicFooterButton from "../../../common/BasicFooterButton";
import { getHiringDetail, postHiringDetail } from "./api/Api";
import {
  isCardEditing,
  isInputEditing,
  isRequiredItem,
} from "pages/common/LayoutFunction";
import { IsCardEditable } from "../../../common/IsEditable";
import {
  handleGetResponse,
  handlePostResponse,
} from "components/handleResponse";
import ToastMsg from "components/ToastMsg";
import { SubmitHandler, useForm } from "react-hook-form";

const HiringDetails = (emp: EmpType) => {
  /* 状態管理パラメータ */
  // 編集状態を管理するフラグ（true：編集中、false：非編集中）
  const [isEdit, setIsEdit] = useState(false);
  // データを保存したかを管理するフラグ
  const [isSave, setIsSave] = useState<boolean>(false);

  /* DB値 */
  const [hiringDetailData, setHiringDetail] = useState<HiringDetailType>();

  /* 画面表示項目 */
  const [inputCompanyName, setCompanyName] = useState(""); //在籍会社
  const [inputLastEducation, setLastEducation] = useState(""); //最終学歴
  const [inputHealthInformation, setHealthInformation] = useState(0); //健康情報

  // バリデーション用のオブジェクト、メソッドを定義
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<HiringDetailType>();

  // モーダルダイアログの開閉を管理するための関数
  const handleClose = () => {
    reset(); // フォームの値をリセット
  };

  /**
   * DB値の反映処理
   *
   * @param hiringDetailData 採用時情報
   */
  const hiringDetailDataToInputValues = (dbHiringDetail: HiringDetailType) => {
    //入力項目に展開
    setCompanyName(dbHiringDetail.companyName ?? "");
    setLastEducation(dbHiringDetail.lastEducation ?? "");
    setHealthInformation(dbHiringDetail.healthInformation);
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    // リロード対象の場合、取得APIを呼び出す
    (async () => {
      await handleGetResponse(getHiringDetail(emp.empDataId)).then((data) => {
        setHiringDetail(data);
        hiringDetailDataToInputValues(data);
        setIsSave(false);
      });
    })();
  }, []);

  /**
   * 編集アイコンクリックハンドラー
   */
  const onClickEditHandler = () => {
    setIsEdit(true);
  };

  /**
   * 「キャンセル」ボタンクリックハンドラー
   */
  const onClickInputClearHandler = () => {
    if (hiringDetailData) {
      hiringDetailDataToInputValues(hiringDetailData);
    }
    reset(); // バリデーションメッセージのリセット
    setIsEdit(false);
  };

  /**
   * 「保存」ボタンクリックハンドラー
   */
  const onClickButtonSaveHandler: SubmitHandler<HiringDetailType> = () => {
    if (hiringDetailData) {
      const HiringDetail: HiringDetailType = {
        dataId: hiringDetailData?.dataId,
        empDataId: emp.empDataId,
        companyName: inputCompanyName,
        lastEducation: inputLastEducation,
        healthInformation: inputHealthInformation,
        updatedAt: hiringDetailData.updatedAt,
      };

      (async () => {
        await handlePostResponse(postHiringDetail(HiringDetail))
          .then((data) => {
            setHiringDetail(data);
            hiringDetailDataToInputValues(data);
            setIsSave(true);
          })
          .catch((error) => {
            console.log("POSTリクエストでエラーが発生しました。");
          });
      })();
    }
    setIsEdit(false);
  };

  return (
    <div style={isCardEditing(isEdit)}>
      {hiringDetailData && (
        <>
          <BasicInfoCardHeader
            headerTitle="採用時情報"
            onClickEditHandler={() => onClickEditHandler()}
            iconType="saiyou"
            editable={IsCardEditable(emp.isEmployed)}
          />
          <CardContent>
            <Box>
              <TextField
                id="company_name"
                label="在籍会社"
                multiline
                rows={4}
                InputProps={{ readOnly: !isEdit }}
                sx={[{ width: 470 }, isInputEditing(isEdit)]}
                value={inputCompanyName}
                onChange={(e) => {
                  setIsEdit(true);
                  setCompanyName(e.target.value);
                }}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                size="small"
                label="最終学歴"
                InputProps={{ readOnly: !isEdit }}
                sx={isInputEditing(isEdit)}
                required
                InputLabelProps={{ component: isRequiredItem }}
                value={inputLastEducation}
                // バリデーションチェック
                {...register("lastEducation", {
                  required: {
                    value: true,
                    message: "最終学歴は必須項目です。",
                  },
                })}
                error={"lastEducation" in errors}
                onChange={(e) => {
                  setIsEdit(true);
                  setLastEducation(e.target.value);
                }}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <FormControl>
                <InputLabel size="small" id="healthInformation">
                  健康情報
                </InputLabel>
                <Select
                  size="small"
                  label="健康情報"
                  inputProps={{ readOnly: !isEdit }}
                  sx={[{ width: "225px" }, isInputEditing(isEdit)]}
                  value={inputHealthInformation}
                  onChange={(e) => {
                    setIsEdit(true);
                    setHealthInformation(Number(e.target.value));
                  }}
                >
                  <MenuItem value={0}>健康情報あり</MenuItem>
                  <MenuItem value={1}>健康情報なし</MenuItem>
                </Select>
              </FormControl>

              {errors.lastEducation && (
                //ダイアログの表示
                <Dialog open={true} onClose={handleClose}>
                  <DialogTitle>エラー</DialogTitle>
                  <DialogContent>
                    {errors.lastEducation && (
                      <DialogContentText>
                        {errors.lastEducation.message}
                      </DialogContentText>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                      閉じる
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              <BasicFooterButton
                isEditing={isEdit}
                mtop={-5}
                mright={1}
                onClickSave={handleSubmit(onClickButtonSaveHandler)}
                onClickCancel={onClickInputClearHandler}
              />
              <ToastMsg isDisp={isSave} isSuccess={true} />
            </Box>
          </CardContent>
        </>
      )}
    </div>
  );
};

export default HiringDetails;
