import { LoginUserType } from "types/LoginUserType";
import { MEmployeesType } from "types/MEmployeesType";
import apiClient from "components/apiClient";

/**
 * ログインユーザ情報取得
 * @param email メールアドレス
 * @returns ログインユーザ情報
 */
export const getLoginUser = async (email: string) => {
  return await apiClient.get<LoginUserType>(
    `${process.env.REACT_APP_HOST}/loginUser`,
    {
      params: { email },
    }
  );
};

/**
 * 社員一覧取得API（検索時）
 * @param empNo 社員番号
 * @param employeeName 社員名
 * @param employeeNameKana 社員名（かな）
 * @param departmentName 部署名
 * @param organizationCode ログインユーザの組織コード
 * @param permissionCode ログインユーザの権限コード
 * @param permissionLevelCode ログインユーザの権限レベルコード
 * @param exclusionCode ログインユーザの除外コード
 * @param empDataId ログインユーザの社員データID
 * @returns 社員一覧
 */
export const searchEmployee = async (
  empNo: string,
  employeeName: string,
  employeeNameKana: string,
  departmentName: string,
  organizationCode: string,
  permissionCode: string,
  permissionLevelCode: string,
  exclusionCode: string,
  empDataId: number
) => {
  return await apiClient.get<MEmployeesType[]>(
    `${process.env.REACT_APP_HOST}/employees`,
    {
      params: {
        empNo,
        employeeName,
        employeeNameKana,
        departmentName,
        organizationCode,
        permissionCode,
        permissionLevelCode,
        exclusionCode,
        empDataId,
      },
    }
  );
};
/**
 * 部署名取得API
 * @returns 部署名リスト
 */
export const getDepartmentNames = async () => {
  return await apiClient.get<String[]>(
    `${process.env.REACT_APP_HOST}/employees/departmentNames`
  );
};
