import { Route, Routes } from "react-router-dom";
import Header from "../components/Header";
import Login from "../components/Login";
import EmployeeSearch from "../pages/employeeSearch/EmployeeSearch";
import TalentDetail from "../pages/employeeDetail/TalentDetail";
import ConcurrentlyPosition from "../pages/concurrentlyPosition/ConcurrentlyPosition";
import ServerError from "../components/ServerError";
export const Router = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Header title="タレントマネジメント" />
            <Login />
          </>
        }
      />
      <Route path="/employeeSearch" element={<EmployeeSearch />} />
      <Route path="/talentdetail/:dataId" element={<TalentDetail />} />
      <Route
        path="/concurrentlyPosition/:empDataId"
        element={<ConcurrentlyPosition />}
      />
      <Route path="/error" element={<ServerError />} />
    </Routes>
  );
};
