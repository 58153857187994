import React from 'react';
import './Modal.css';
import { Button } from '@mui/material';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className='modal-overlay'>
            <div className='modal'>
                <Button className='close-button' sx={{ display: "flex" }} onClick={onClose}></Button>
                {children}
            </div>
        </div>
    )
};

export default Modal;