export const PermissionLevelCode = {
  ALL_EMPLOYEES: "K1", // 全社員
  AFFILIATED_HEADQUARTERS: "K2", // 所属本部
  AFFILIATED_GENERAL_HEADQUARTERS: "K3", // 所属統括本部
  AFFILIATED_BUSINESS_HEADQUARTERS: "K4", // 所属事業本部
  AFFILIATED_GROUP: "K5", // 所属グループ
  MYSELF: "K6", // 本人
  ALL_EMPLOYEES_EXCLUDING_OWN_DIVISION: "K7", // 全社員（自所属を除く）
  ADMIN: "K8", // Admin
};

export const Semester = {
  FIRST: 1,
  SECOND: 2,
};