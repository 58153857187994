import { Alert } from "@mui/material";

export interface ErrorMsgIf {
  msg: string;
  isDisp: boolean;
}

const ErrorMsg = (props: ErrorMsgIf) => {
  return (
    <>
      {props.isDisp && (
        <>
          <Alert severity="error">{props.msg}</Alert>
        </>
      )}
    </>
  );
};

export default ErrorMsg;
