import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import BasicInfoCardHeader from "pages/common/BasicInfoCardHeader";

import {
  Box,
  Button,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { PersonalType } from "types/basicInfo/PersonalType";
import { EmpType } from "types/EmpType";
import { getPersonal, getPrefecture, postPersonal } from "./api/Api";
import { PrefectureMasterType } from "types/master/PrefectureMasterType";
import {
  isCardEditing,
  isInputEditing,
  isRequiredItem,
  selectRequiredItem,
} from "pages/common/LayoutFunction";
import BasicFooterButton from "../../../common/BasicFooterButton";
import { IsCardEditable } from "../../../common/IsEditable";
import {
  handleGetResponse,
  handlePostResponse,
} from "components/handleResponse";
import ToastMsg from "components/ToastMsg";

// 出身地のその他を選択した際のpref_code(その他を選択した際の入力欄表示の分岐に使用)
const othersBirthplaceCode = 48;

const Personal = (emp: EmpType) => {
  /* 状態管理パラメータ */
  // 編集状態を管理するフラグ（true：編集中、false：非編集中）
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // モーダルダイアログの開閉を管理するフラグ（true：開く、false：閉じる）
  const [isOpen, setIsOpen] = useState<boolean>(true);
  // データを保存したかを管理するフラグ
  const [isSave, setIsSave] = useState<boolean>(false);

  /* DB値 */
  const [personalData, setPersonal] = useState<PersonalType>(); //パーソナルの変数セット
  const [inputPrefecture, setPrefecture] = useState<PrefectureMasterType[]>([]); //都道府県コードと名称

  /* 画面表示項目 */
  const [inputBirthplacePrefCode, setBirthplacePrefCode] = useState(0); //出身地
  const [inputBirthplaceOther, setBirthplaceOther] = useState(""); //出身地(その他)
  const [inputResidencePrefCode, setResidencePrefCode] = useState(0); //居住地
  const [inputIsMarriage, setIsMarriage] = useState(0); //結婚
  const [inputChildrenCount, setChildrenCount] = useState<number | null>(null); //子供
  const [inputDependentsCount, setDependentsCount] = useState<number | null>(
    null
  ); //扶養人数
  const [inputRequiredCareFamily, setRequiredCareFamily] = useState(0); //介護すべき家族

  // バリデーション用のオブジェクト、メソッドを定義
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<PersonalType>();

  // モーダルダイアログの開閉を管理するための関数
  const handleClose = () => {
    setIsOpen(false);
    reset(); // フォームの値をリセット
  };

  /**
   * DB値の反映処理
   * @param personalData パーソナルデータ
   */
  const dbDataToInputValues = (personalData: PersonalType) => {
    // 入力項目に展開
    setBirthplacePrefCode(personalData.birthplacePrefCode ?? "");
    setBirthplaceOther(personalData.birthplaceOther ?? "");
    setResidencePrefCode(personalData.residencePrefCode ?? "");
    setIsMarriage(personalData.isMarriage ?? "");
    setChildrenCount(personalData.childrenCount ?? null);
    setDependentsCount(personalData.dependentsCount ?? null);
    setRequiredCareFamily(personalData.requiredCareFamily ?? "");
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    // リロード対象の場合、取得APIを呼び出す
    (async () => {
      await handleGetResponse(getPersonal(emp.empDataId))
        .then((data) => {
          setPersonal(data);
          dbDataToInputValues(data);
          setIsSave(false);
        })
        .catch((error) => {
          console.log("GETリクエストでエラーが発生しました");
        });
    })();

    // 都道府県マスタ情報を取得
    (async () => {
      await handleGetResponse(getPrefecture())
        .then((data) => {
          setPrefecture(data);
        })
        .catch((error) => {
          console.log("GETリクエストでエラーが発生しました。");
        });
    })();
  }, []);

  /**
   * 編集アイコンクリックハンドラー
   */
  const onClickEditHandler = () => {
    setIsEdit(true);
  };

  /**
   * 「キャンセル」ボタンクリックハンドラー
   */
  const onClickInputClearHandler = () => {
    if (personalData) {
      dbDataToInputValues(personalData);
    }
    reset(); // バリデーションメッセージのリセット
    setIsEdit(false);
  };

  /**
   * 「保存」ボタンクリックハンドラー
   */
  const onClickButtonSaveHandler: SubmitHandler<PersonalType> = () => {
    if (personalData) {
      // 更新リクエスト時のパラメータを設定する
      const inputValuePersonalData: PersonalType = {
        dataId: personalData?.dataId,
        empDataId: emp.empDataId,
        birthday: personalData?.birthday as string,
        age: getAge(personalData?.birthday as string),
        station: personalData?.station as string,
        residencePrefCode: inputResidencePrefCode,
        birthplacePrefCode: inputBirthplacePrefCode,
        birthplaceOther: inputBirthplaceOther,
        isMarriage: inputIsMarriage,
        childrenCount: Number(inputChildrenCount), // 必須項目のため数値のみを値に持つが、型定義がnumber | nullのため明示的にNumber型にキャスト
        dependentsCount: Number(inputDependentsCount), // 必須項目のため数値のみを値に持つが、型定義がnumber | nullのため明示的にNumber型にキャスト
        requiredCareFamily: inputRequiredCareFamily,
        updatedAt: personalData.updatedAt,
      };

      (async () => {
        await handlePostResponse(postPersonal(inputValuePersonalData))
          .then((data) => {
            setPersonal(data);
            dbDataToInputValues(data);
            setIsSave(true);
          })
          .catch((error) => {
            console.log("POSTリクエストでエラーが発生しました");
          });
      })();
    }
    // 保存押下時に更新APiを起動
    setIsEdit(false);
  };

  /**
   * 年齢を計算する関数
   */
  const getAge = (birthday: string): number => {
    if (!birthday) {
      return 0;
    }

    // YYYYMMDD形式の日付文字列を取得
    const todate = new Date()
      .toLocaleDateString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replaceAll("/", "");
    const birthdate = birthday.replaceAll("-", "");

    // 年、月、日ごとに切り出して数値に変換
    const todateYear = parseInt(todate.substring(0, 4));
    const todateMonth = parseInt(todate.substring(4, 6));
    const todateDay = parseInt(todate.substring(6, 8));

    const birthdateYear = parseInt(birthdate.substring(0, 4));
    const birthdateMonth = parseInt(birthdate.substring(4, 6));
    const birthdateDay = parseInt(birthdate.substring(6, 8));

    // 年齢を計算
    let age = todateYear - birthdateYear;

    // 誕生日を迎えていない場合、1を引く(➀誕生月＞現在月は、確定 ➁誕生月＝現在月は、日付まで使って判断)
    if (birthdateMonth > todateMonth) {
      age--;
    }
    if (birthdateMonth === todateMonth && birthdateDay > todateDay) {
      age--;
    }
    return age;
  };

  /**
   * 都道府県マスターからその他を削除する関数
   */
  const excludeOtherOptionList = (inputPrefecture: PrefectureMasterType[]) => {
    var prefectureExceptOthers = [...inputPrefecture];
    return prefectureExceptOthers.filter(
      (prefecture) => prefecture.prefCode !== othersBirthplaceCode
    );
  };

  return (
    <div style={isCardEditing(isEdit)}>
      {personalData && (
        <>
          <BasicInfoCardHeader
            headerTitle="パーソナル"
            onClickEditHandler={() => onClickEditHandler()}
            iconType="def"
            editable={IsCardEditable(emp.isEmployed)}
          />

          <Box>
            <CardContent>
              <Box>
                <TextField
                  size="small"
                  label="生年月日"
                  InputProps={{ readOnly: true }}
                  sx={[{ width: "224px" }, isInputEditing(false)]}
                  required
                  InputLabelProps={{ component: isRequiredItem }}
                  value={personalData.birthday}
                />
                <TextField
                  size="small"
                  label="年齢"
                  type="number"
                  InputProps={{ readOnly: true }}
                  sx={[{ ml: 2, width: "224px" }, isInputEditing(false)]}
                  required
                  InputLabelProps={{ component: isRequiredItem }}
                  value={getAge(personalData.birthday)}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  size="small"
                  label="最寄駅"
                  InputProps={{ readOnly: true }}
                  sx={[{ width: "224px" }, isInputEditing(false)]}
                  required
                  InputLabelProps={{ component: isRequiredItem }}
                  value={personalData.station}
                />
                <FormControl>
                  <InputLabel
                    size="small"
                    id="ResidencePrefCode"
                    required
                    sx={[{ ml: 2 }, selectRequiredItem]}
                  >
                    居住地
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="ResidencePrefCode"
                    id="ResidencePrefCode-select"
                    label="居住地"
                    inputProps={{ readOnly: !isEdit }}
                    sx={[{ ml: 2, width: "224px" }, isInputEditing(isEdit)]}
                    MenuProps={{
                      sx: {
                        maxHeight: 400,
                      },
                    }}
                    value={inputResidencePrefCode}
                    // バリデーションチェック
                    {...register("residencePrefCode", {
                      required: {
                        value: true,
                        message: "居住地は必須項目です。",
                      },
                    })}
                    error={"residencePrefCode" in errors}
                    onChange={(e) => {
                      setResidencePrefCode(Number(e.target.value));
                    }}
                  >
                    {excludeOtherOptionList(inputPrefecture).map((x) => (
                      <MenuItem value={x.prefCode}>{x.prefName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormControl>
                  <InputLabel
                    size="small"
                    id="BirthplacePrefCode"
                    required
                    sx={selectRequiredItem}
                  >
                    出身地
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="BirthplacePrefCode"
                    id="BirthplacePrefCode-select"
                    label="出身地"
                    inputProps={{ readOnly: !isEdit }}
                    sx={[{ width: "224px" }, isInputEditing(isEdit)]}
                    MenuProps={{
                      sx: {
                        maxHeight: 400,
                      },
                    }}
                    value={inputBirthplacePrefCode}
                    // バリデーションチェック
                    {...register("birthplacePrefCode", {
                      required: {
                        value: true,
                        message: "出身地は必須項目です。",
                      },
                    })}
                    error={"birthplacePrefCode" in errors}
                    onChange={(e) => {
                      setBirthplacePrefCode(Number(e.target.value));
                    }}
                  >
                    {inputPrefecture.map((x) => (
                      <MenuItem value={x.prefCode}>{x.prefName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  {inputBirthplacePrefCode === othersBirthplaceCode && (
                    <TextField
                      size="small"
                      label="出身地"
                      InputProps={{ readOnly: !isEdit }}
                      sx={[{ ml: 2, width: "224px" }, isInputEditing(isEdit)]}
                      required
                      InputLabelProps={{ component: isRequiredItem }}
                      value={inputBirthplaceOther}
                      // バリデーションチェック
                      {...register("birthplaceOther", {
                        required: {
                          value: true,
                          message: "出身地は必須項目です。",
                        },
                      })}
                      error={"birthplaceOther" in errors}
                      onChange={(e) => {
                        setBirthplaceOther(e.target.value);
                      }}
                    />
                  )}
                </FormControl>
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormControl>
                  <InputLabel
                    size="small"
                    id="isMarriage"
                    required
                    sx={selectRequiredItem}
                  >
                    結婚
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="isMarriage"
                    id="isMarriage-select"
                    label="結婚"
                    inputProps={{ readOnly: !isEdit }}
                    sx={[{ width: "224px" }, isInputEditing(isEdit)]}
                    value={inputIsMarriage}
                    // バリデーションチェック
                    {...register("isMarriage", {
                      required: {
                        value: true,
                        message: "結婚は必須項目です。",
                      },
                    })}
                    error={"isMarriage" in errors}
                    onChange={(e) => {
                      setIsMarriage(Number(e.target.value));
                    }}
                  >
                    <MenuItem value={1}>YES</MenuItem>
                    <MenuItem value={0}>NO</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  size="small"
                  label="子ども"
                  type="number"
                  InputProps={{ readOnly: !isEdit }}
                  sx={[{ ml: 2, width: "224px" }, isInputEditing(isEdit)]}
                  required
                  InputLabelProps={{ component: isRequiredItem }}
                  value={inputChildrenCount}
                  {...register("childrenCount", {
                    required: {
                      value: true,
                      message: "子どもは必須項目です。",
                    },
                    pattern: {
                      value: /^(0|[1-9][0-9]?)$/,
                      message: "子どもは2桁以下の整数を入力してください。",
                    },
                  })}
                  error={"childrenCount" in errors}
                  onChange={(e) => {
                    setChildrenCount(
                      e.target.value === "" ? null : Number(e.target.value)
                    );
                  }}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  size="small"
                  label="扶養人数"
                  type="number"
                  InputProps={{ readOnly: !isEdit }}
                  sx={[{ width: "224px" }, isInputEditing(isEdit)]}
                  required
                  InputLabelProps={{ component: isRequiredItem }}
                  value={inputDependentsCount}
                  {...register("dependentsCount", {
                    required: {
                      value: true,
                      message: "扶養人数は必須項目です。",
                    },
                    pattern: {
                      value: /^(0|[1-9][0-9]?)$/,
                      message: "扶養人数は2桁以下の整数で入力してください。",
                    },
                  })}
                  error={"dependentsCount" in errors}
                  onChange={(e) => {
                    setDependentsCount(
                      e.target.value === "" ? null : Number(e.target.value)
                    );
                  }}
                />
                <FormControl>
                  <InputLabel
                    size="small"
                    id="requiredCareFamily"
                    required
                    sx={[{ ml: 2 }, selectRequiredItem]}
                  >
                    介護すべき家族
                  </InputLabel>
                  <Select
                    size="small"
                    label="介護すべき家族"
                    inputProps={{ readOnly: !isEdit }}
                    sx={[{ ml: 2, width: "224px" }, isInputEditing(isEdit)]}
                    value={inputRequiredCareFamily}
                    // バリデーションチェック
                    {...register("requiredCareFamily", {
                      required: {
                        value: true,
                        message: "介護すべき家族は必須項目です。",
                      },
                    })}
                    error={"requiredCareFamily" in errors}
                    onChange={(e) => {
                      setRequiredCareFamily(Number(e.target.value));
                    }}
                  >
                    {/* 不明をいれる */}
                    <MenuItem value={0}>いない</MenuItem>
                    <MenuItem value={1}>いる</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {(errors.residencePrefCode ||
                errors.birthplacePrefCode ||
                errors.birthplaceOther ||
                errors.isMarriage ||
                errors.childrenCount ||
                errors.dependentsCount ||
                errors.requiredCareFamily) && (
                //ダイアログの表示
                <Dialog open={true} onClose={handleClose}>
                  <DialogTitle>エラー</DialogTitle>
                  <DialogContent>
                    {errors.residencePrefCode && (
                      <DialogContentText>
                        {errors.residencePrefCode.message}
                      </DialogContentText>
                    )}
                    {errors.birthplacePrefCode && (
                      <DialogContentText>
                        {errors.birthplacePrefCode.message}
                      </DialogContentText>
                    )}
                    {errors.birthplaceOther && (
                      <DialogContentText>
                        {errors.birthplaceOther.message}
                      </DialogContentText>
                    )}
                    {errors.isMarriage && (
                      <DialogContentText>
                        {errors.isMarriage.message}
                      </DialogContentText>
                    )}
                    {errors.childrenCount && (
                      <DialogContentText>
                        {errors.childrenCount.message}
                      </DialogContentText>
                    )}
                    {errors.dependentsCount && (
                      <DialogContentText>
                        {errors.dependentsCount.message}
                      </DialogContentText>
                    )}
                    {errors.requiredCareFamily && (
                      <DialogContentText>
                        {errors.requiredCareFamily.message}
                      </DialogContentText>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                      閉じる
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
              <BasicFooterButton
                isEditing={isEdit}
                mtop={2}
                mright={1}
                onClickCancel={onClickInputClearHandler}
                onClickSave={handleSubmit(onClickButtonSaveHandler)}
              />
              <ToastMsg isDisp={isSave} isSuccess={true} />
            </CardContent>
          </Box>
        </>
      )}
    </div>
  );
};

export default Personal;
