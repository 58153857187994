import { HumanResourceType } from "../../../../../types/basicInfo/HumanResourceType";
import { CareerType } from "../../../../../types/basicInfo/CareerType";
import { PersonalType } from "../../../../../types/basicInfo/PersonalType";
import { FfsResultType } from "../../../../../types/basicInfo/FfsResultType";
import { HiringDetailType } from "../../../../../types/basicInfo/HiringDetailType";
import { ProfileType } from "../../../../../types/basicInfo/ProfileType";
import { HumanType } from "../../../../../types/relationship/HumanType";
import { LaborType } from "../../../../../types/basicInfo/LaborType";
import { MemoType } from "../../../../../types/basicInfo/MemoType";
import { ExternalActivityType } from "../../../../../types/basicInfo/ExternalActivityType";
import { BasicInfoType } from "../../../../../types/basicInfo/BasicInfoType";
import { PrefectureMasterType } from "../../../../../types/master/PrefectureMasterType";
import { ClubActivityMasterType } from "../../../../../types/master/ClubActivityMasterType";
import { EvaluatorListType } from "types/basicInfo/EvaluatorListType";
import apiClient from "components/apiClient";

/**
 * 社員基本情報取得API
 * @param dataId データID
 * @returns 社員基本情報
 */
export const getBasicInfo = async (dataId: number) => {
  return await apiClient.get<BasicInfoType>(
    `${process.env.REACT_APP_HOST}/employees/${dataId}/basic-info`
  );
};

/**
 * ログインユーザ情報取得API
 * @param email メールアドレス
 * @returns ログインユーザ情報
 */
export const getBasicInfoByEmail = async (email: string) => {
  return await apiClient.get<BasicInfoType>(
    `${process.env.REACT_APP_HOST}/basic-info/${email}`
  );
};

/**
 * 人事情報取得API
 * @param empDataId 社員データID
 * @returns 人事情報
 */
export const getHumanResource = async (empDataId: number) => {
  return await apiClient.get<HumanResourceType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/human-resource`
  );
};

/**
 * 人事評価者リスト取得API
 * @param empDataId 社員データID
 * @returns 人事評価者リスト
 */
export const getEvaluatorList = async (empDataId: number) => {
  return await apiClient.get<EvaluatorListType[]>(
    `${process.env.REACT_APP_HOST}/employees/evaluator-list`
  );
};

/**
 * キャリア情報取得API
 * @param empDataId 社員データID
 * @returns キャリア情報取得結果
 */
export const getCarrer = async (empDataId: number) => {
  return await apiClient.get<CareerType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/career`
  );
};

/**
 * キャリア情報更新API
 * @param data キャリア情報DTO
 * @returns キャリア情報更新結果
 */
export const postCarrer = async (data: CareerType) => {
  return await apiClient.post(
    `${process.env.REACT_APP_HOST}/employees/career`,
    data
  );
};

/**
 * パーソナル情報取得API
 * @param empDataId 社員データID
 * @returns パーソナル情報
 */
export const getPersonal = async (empDataId: number) => {
  return await apiClient.get<PersonalType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/personal`
  );
};

/**
 * パーソナル情報更新API
 * @param data パーソナル情報DTO
 * @returns パーソナル情報
 */
export const postPersonal = async (data: PersonalType) => {
  return await apiClient.post(
    `${process.env.REACT_APP_HOST}/employees/personal`,
    data
  );
};

/**
 * FFS診断結果取得API
 * @param empDataId 社員データID
 * @returns FS診断取得結果
 */
export const getFfs = async (empDataId: number) => {
  return await apiClient.get<FfsResultType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/ffs`
  );
};

/**
 * 採用時情報取得API
 * @param empDataId 社員データID
 * @returns 採用時情報
 */
export const getHiringDetail = async (empDataId: number) => {
  return await apiClient.get<HiringDetailType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/hiring-detail`
  );
};

/**
 * 採用時情報更新API
 * @param data 採用時情報DTO
 * @returns 採用時情報
 */
export const postHiringDetail = async (data: HiringDetailType) => {
  return await apiClient.post(
    `${process.env.REACT_APP_HOST}/employees/hiring-detail`,
    data
  );
};

/**
 * プロフィール情報取得API
 * @param empDataId 社員データID
 * @returns プロフィール情報
 */
export const getProfile = async (empDataId: number) => {
  return await apiClient.get<ProfileType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/profile`
  );
};

/**
 * プロフィール情報更新API
 * @param data プロフィール情報DTO
 * @returns プロフィール情報
 */
export const postProfile = async (data: ProfileType) => {
  return await apiClient.post(
    `${process.env.REACT_APP_HOST}/employees/profile`,
    data
  );
};

/**
 * メモ情報取得API
 * @param empDataId 社員データID
 * @returns メモ情報
 */
export const getMemo = async (empDataId: number) => {
  return await apiClient.get<MemoType>(`/employees/${empDataId}/memo`);
};

/**
 * メモ更新API
 * @param data メモ情報DTO
 * @returns メモ情報
 */
export const postMemo = async (data: MemoType) => {
  return await apiClient.post(`/employees/memo`, data);
};

/**
 * ヒューマン情報取得API
 * @param empDataId 社員データID
 * @returns ヒューマン情報
 */
export const getHuman = async (empDataId: number) => {
  return await apiClient.get<HumanType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/human`
  );
};

/**
 * ヒューマン情報更新API
 * @param data ヒューマン情報DTO
 * @returns ヒューマン情報
 */
export const postHuman = async (data: HumanType) => {
  return await apiClient.post<HumanType>(
    `${process.env.REACT_APP_HOST}/employees/human`,
    data
  );
};

/**
 * 労務取得API
 * @param empDataId 社員データID
 * @returns 労務情報
 */
export const getLabor = async (empDataId: number) => {
  return await apiClient.get<LaborType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/labor`
  );
};

/**
 * 労務更新API
 * @param data 労務情報DTO
 * @returns 労務情報
 */
export const postLabor = async (data: LaborType) => {
  return await apiClient.post(
    `${process.env.REACT_APP_HOST}/employees/labor`,
    data
  );
};

/**
 * 社外広報活動情報取得API
 * @param empDataId 社員データID
 * @returns 社外広報活動情報
 */
export const getExternalActivity = async (empDataId: number) => {
  return await apiClient.get<ExternalActivityType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/external-activity`
  );
};

/**
 * 社外広報活動情報更新API
 * @param data 社外広報活動情報DTO
 * @returns 社外広報活動情報
 */
export const postExternalActivity = async (data: ExternalActivityType) => {
  return await apiClient.post(
    `${process.env.REACT_APP_HOST}/employees/external-activity`,
    data
  );
};

/**
 * 人事情報更新API
 * @param dataId データID
 * @param data 人事情報DTO
 * @returns 人事情報
 */
export const postHumanResource = async (data: HumanResourceType) => {
  return await apiClient.post(
    `${process.env.REACT_APP_HOST}/employees/human-resource`,
    data
  );
};

/**
 * 都道府県マスタ取得API
 * @returns 都道府県情報
 */
export const getPrefecture = async () => {
  return await apiClient.get<PrefectureMasterType[]>(
    `${process.env.REACT_APP_HOST}/master/getPrefectureMaster`
  );
};

/**
 * 部活動マスタ取得API
 * @returns 部活動情報
 */
export const getClubActivitieList = async () => {
  return await apiClient.get<ClubActivityMasterType[]>(
    `${process.env.REACT_APP_HOST}/master/getClubActivityMaster`
  );
};
