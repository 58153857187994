import { Box } from "@mui/material";
import RectangleIcon from "@mui/icons-material/Rectangle";
import Typography from "@mui/material/Typography";

type lineLabel = {
  colorCode: string;
  labelName: string;
};

const LineLabel = ({ colorCode, labelName }: lineLabel) => {
  return (
    <>
      <Box sx={{ width: 150 }}>
        <RectangleIcon
          style={{
            color: colorCode,
            fontSize: "9px",
            marginRight: "5px",
          }}
        />
        <Typography
          variant="caption"
          component="span"
          sx={{ flexGrow: 1, marginRight: "5px" }}
        >
          {labelName}
        </Typography>
      </Box>
    </>
  );
};
export default LineLabel;
