import axios, { AxiosResponse } from "axios";
import { ErrorMsgIf } from "../components/ErrorMsg";

const commonAxios = axios.create();

// エラーページへの遷移を設定するコールバック
let navigateCallback: ((path: string) => void) | null = null;

// エラーページへの遷移を設定する関数
export const setNavigateCallback = (callback: (path: string) => void) => {
  navigateCallback = callback;
};

const redirectToErrorPage = () => {
  if (navigateCallback) {
    navigateCallback("/error");
  }
};

// エラーメッセージを設定するコールバック
let errMsgCallback: ((errMsgIf: ErrorMsgIf) => void) | null = null;

// エラーメッセージを設定する関数
export const setErrMsgCallback = (callback: (errMsgIf: ErrorMsgIf) => void) => {
  errMsgCallback = callback;
};

const setErrMsg = (errMsg: string) => {
  if (errMsgCallback) {
    errMsgCallback({ msg: errMsg, isDisp: true });
  }
};

commonAxios.interceptors.response.use(
  function (response: AxiosResponse<any, any>) {
    // HTTPステータスコードが200番台の場合、レスポンスを返す
    return response;
  },
  function (error) {
    if (error.response) {
      let errMsg: string = "";
      let status: number = error.response.status;
      if (status >= 500) {
        // 500番台のエラーの場合、エラーページに遷移する
        redirectToErrorPage();
      } else if (status >= 400) {
        // 400番台のエラーの場合、親コンポーネントにエラーメッセージを表示する
        switch (status) {
          case 400:
            errMsg = "リクエストが無効です。";
            break;
          case 401:
            errMsg = "認証が必要です。";
            break;
          case 403:
            errMsg = "アクセスが拒否されました。";
            break;
          case 404:
            errMsg = "リソースが見つかりません。";
            break;
          default:
            errMsg = "エラーが発生しました。";
        }
      }

      if (errMsg !== "") {
        // エラーメッセージを親コンポーネントに表示
        setErrMsg(errMsg);
      }
    } else {
      // レスポンスがundefinedの場合
      redirectToErrorPage();
    }
    return Promise.reject(error);
  }
);

export default commonAxios;
