import { useEffect, useState } from "react";
import BasicInfoCardHeader from "../../../common/BasicInfoCardHeader";
import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { EmpType } from "../../../../types/EmpType";
import { HumanType } from "../../../../types/relationship/HumanType";
import { getHuman, postHuman } from "../../basicInfo/contents/api/Api";
import {
  isCardEditing,
  isInputEditing,
  isRequiredItem,
} from "pages/common/LayoutFunction";
import BasicFooterButton from "../../../common/BasicFooterButton";
import { IsCardEditable } from "../../../common/IsEditable";
import {
  handleGetResponse,
  handlePostResponse,
} from "components/handleResponse";
import ToastMsg from "components/ToastMsg";
import { useForm } from "react-hook-form";

const Humans = (emp: EmpType) => {
  /* 状態管理パラメータ */
  // 編集状態を管理するフラグ（true：編集中、false：非編集中）
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // データを保存したかを管理するフラグ
  const [isSave, setIsSave] = useState<boolean>(false);

  /* DB値 */
  const [humanData, setHuman] = useState<HumanType>();

  /* 画面表示項目 */
  const [inputStory, setStory] = useState(""); //ストーリー

  // バリデーション用のオブジェクト、メソッドを定義
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<HumanType>();

  // モーダルダイアログの開閉を管理するための関数
  const handleClose = () => {
    reset(); // フォームの値をリセット
  };
  /**
   * DB値の反映処理
   * @param humanData ヒューマン情報
   */
  const dbDataToInputValues = (humanData: HumanType) => {
    // 入力項目に展開
    setStory(humanData.story ?? "");
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    // リロード対象の場合、取得APIを呼び出す
    (async () => {
      await handleGetResponse(getHuman(emp.empDataId)).then((data) => {
        setHuman(data);
        dbDataToInputValues(data);
        setIsSave(false);
      });
    })();
  }, []);

  /**
   * 編集アイコンクリックハンドラー
   */
  const onClickEditHandler = () => {
    setIsEdit(true);
  };

  /**
   * 「キャンセル」ボタンクリックハンドラー
   */
  const onClickInputClearHandler = () => {
    if (humanData) {
      // 画面項目のクリア（DB値に戻す）
      dbDataToInputValues(humanData);
    }
    setIsEdit(false);
  };

  /**
   * 「保存」ボタンクリックハンドラー
   */
  const onClickButtonSaveHandler = () => {
    if (humanData) {
      // 更新リクエスト時のパラメータを設定する
      const humans: HumanType = {
        dataId: humanData?.dataId,
        empDataId: emp.empDataId,
        story: inputStory,
        updatedAt: humanData.updatedAt,
      };

      (async () => {
        await handlePostResponse(postHuman(humans))
          .then((data) => {
            setHuman(data);
            dbDataToInputValues(data);
            setIsSave(true);
          })
          .catch((error) => {
            console.error("POSTリクエストでエラーが発生しました。");
          });
      })();
    }
    setIsEdit(false);
  };

  return (
    <div style={isCardEditing(isEdit)}>
      {humanData && (
        <>
          <BasicInfoCardHeader
            headerTitle="ヒューマン"
            onClickEditHandler={() => onClickEditHandler()}
            iconType="hail"
            editable={IsCardEditable(emp.isEmployed)}
          />
          <CardContent>
            <TextField
              id="outlined-multiline-static"
              label="STORY"
              multiline
              required
              rows={4}
              value={inputStory}
              // バリデーションチェック
              {...register("story", {
                required: {
                  value: true,
                  message: "STORYは必須項目です。",
                },
              })}
              error={"story" in errors}
              onChange={(e) => setStory(e.target.value)}
              sx={[{ width: 260 }, isInputEditing(isEdit)]}
              InputProps={{ readOnly: !isEdit }}
              InputLabelProps={{ component: isRequiredItem }}
            />
            {errors.story && (
              //ダイアログの表示
              <Dialog open={true} onClose={handleClose}>
                <DialogTitle>エラー</DialogTitle>
                <DialogContent>
                  {errors.story && (
                    <DialogContentText>
                      {errors.story.message}
                    </DialogContentText>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                    閉じる
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            <BasicFooterButton
              isEditing={isEdit}
              mtop={1}
              mright={1}
              onClickSave={handleSubmit(onClickButtonSaveHandler)}
              onClickCancel={onClickInputClearHandler}
            />
            <ToastMsg isDisp={isSave} isSuccess={true} />
          </CardContent>
        </>
      )}
    </div>
  );
};

export default Humans;
