import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import BasicInfoCardHeader from "../../../common/BasicInfoCardHeader";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { EmpType } from "../../../../types/EmpType";
import { ExternalActivityType } from "../../../../types/basicInfo/ExternalActivityType";
import { getExternalActivity, postExternalActivity } from "./api/Api";
import {
  isCardEditing,
  isInputEditing,
  isRequiredItem,
} from "pages/common/LayoutFunction";
import BasicFooterButton from "../../../common/BasicFooterButton";
import { IsCardEditable } from "../../../common/IsEditable";
import {
  handleGetResponse,
  handlePostResponse,
} from "components/handleResponse";
import ToastMsg from "components/ToastMsg";

const ExternalActivities = (emp: EmpType) => {
  /* 状態管理パラメータ */
  // 編集状態を管理するフラグ（true：編集中、false：非編集中）
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // モーダルダイアログの開閉を管理するフラグ（true：開いている、false：閉まっている）
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // データを保存したかを管理するフラグ
  const [isSave, setIsSave] = useState<boolean>(false);

  /* DB値 */
  const [externalActivityData, setExternalActivity] =
    useState<ExternalActivityType>();

  /* 画面表示項目 */
  // ブログ数
  const [inputNumberOfBlogs, setNumberOfBlogs] = useState<number | null>(null);
  // 講演・寄稿
  const [inputLecturesContributions, setLecturesContributions] = useState("");
  // カンタービレ記者ID
  const [inputWriterId, setWriterId] = useState(0);

  // バリデーション用のオブジェクト、メソッドを定義
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ExternalActivityType>();

  // モーダルダイアログの開閉を管理するための関数
  const handleClose = () => {
    setIsOpen(false);
    reset(); // フォームの値をリセット
  };

  /**
   * DB値の反映処理
   * @param dbExternalActivity 社外広報活動情報
   */
  const dbDataToInputValues = (dbExternalActivity: ExternalActivityType) => {
    // 入力項目に展開
    setNumberOfBlogs(dbExternalActivity.numberOfBlogs ?? null);
    setLecturesContributions(dbExternalActivity.lecturesContributions ?? "");
    setWriterId(dbExternalActivity.writerId);
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    (async () => {
      await handleGetResponse(getExternalActivity(emp.empDataId)).then(
        (data) => {
          setExternalActivity(data);
          dbDataToInputValues(data);
          setIsSave(false);
        }
      );
    })();
  }, []);

  /**
   * 「キャンセル」ボタンクリックハンドラー
   */
  const onClickInputClearHandler = () => {
    if (externalActivityData) {
      dbDataToInputValues(externalActivityData);
    }
    reset(); // バリデーションメッセージのリセット
    setIsEdit(false);
  };

  /**
   * 編集アイコンクリックハンドラー
   */
  const onClickEditHandler = () => {
    setIsEdit(true);
  };

  /**
   * 「保存」ボタンクリックハンドラー
   */
  const onClickButtonSaveHandler: SubmitHandler<ExternalActivityType> = () => {
    if (externalActivityData) {
      // 更新リクエスト時のパラメータを設定する
      const externalActivities: ExternalActivityType = {
        dataId: externalActivityData?.dataId,
        empDataId: emp.empDataId,
        numberOfBlogs: Number(inputNumberOfBlogs), // 必須項目のため数値のみを値に持つが、型定義がnumber | nullのため明示的にNumber型にキャスト
        lecturesContributions: inputLecturesContributions,
        writerId: inputWriterId,
        updatedAt: externalActivityData.updatedAt,
      };

      (async () => {
        await handlePostResponse(postExternalActivity(externalActivities))
          .then((data) => {
            setExternalActivity(data);
            dbDataToInputValues(data);
            setIsSave(true);
          })
          .catch((error) => {
            console.error("POSTリクエストでエラーが発生しました。");
          });
      })();
    }
    setIsEdit(false);
  };

  return (
    <div style={isCardEditing(isEdit)}>
      {externalActivityData && (
        <>
          <BasicInfoCardHeader
            headerTitle="社外広報活動"
            onClickEditHandler={() => onClickEditHandler()}
            iconType="campaign"
            editable={IsCardEditable(emp.isEmployed)}
          />
          <CardContent>
            <Box>
              <TextField
                sx={isInputEditing(isEdit)}
                size="small"
                label="カンタービレ投稿数"
                type="number"
                required
                InputLabelProps={{ component: isRequiredItem }}
                value={inputNumberOfBlogs}
                {...register("numberOfBlogs", {
                  required: {
                    value: true,
                    message: "カンタービレ投稿数は必須項目です。",
                  },
                  pattern: {
                    value: /^([1-9]\d{0,4}|0)$/,
                    message:
                      "ブログ数は0以上の5桁以下の整数で入力してください。",
                  },
                })}
                error={"numberOfBlogs" in errors}
                onChange={(e) => {
                  setNumberOfBlogs(
                    e.target.value === "" ? null : Number(e.target.value)
                  );
                }}
                InputProps={{ readOnly: !isEdit }}
              />
              <Button
                target="_blank"
                href={`https://cantabile.alhinc.jp/writer/${inputWriterId}`}
                sx={{ textTransform: "none" }}
              >
                <LaunchIcon sx={{ fontSize: 14 }} />
                <span style={{ fontSize: 14 }}>{"記事の一覧を開く"}</span>
              </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                sx={isInputEditing(isEdit)}
                size="small"
                label="講演・寄稿"
                value={inputLecturesContributions}
                onChange={(e) => {
                  setLecturesContributions(e.target.value);
                }}
                InputProps={{ readOnly: !isEdit }}
              />

              {errors.numberOfBlogs && (
                // ダイアログの表示
                <Dialog open={true} onClose={handleClose}>
                  <DialogTitle>エラー</DialogTitle>
                  <DialogContent>
                    {errors.numberOfBlogs && (
                      <DialogContentText>
                        {errors.numberOfBlogs.message}
                      </DialogContentText>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                      閉じる
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              <BasicFooterButton
                isEditing={isEdit}
                mtop={-5}
                mright={1}
                onClickSave={handleSubmit(onClickButtonSaveHandler)}
                onClickCancel={onClickInputClearHandler}
              />
              <ToastMsg isDisp={isSave} isSuccess={true} />
            </Box>
          </CardContent>
        </>
      )}
    </div>
  );
};

export default ExternalActivities;
