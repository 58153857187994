import { useEffect, useState } from "react";
import BasicInfoCardHeader from "../../../common/BasicInfoCardHeader";
import BasicFooterButton from "../../../common/BasicFooterButton";
import {
  Autocomplete,
  Box,
  CardContent,
  TextField,
  FormControl,
} from "@mui/material";
import { EmpType } from "../../../../types/EmpType";
import { ProfileType } from "../../../../types/basicInfo/ProfileType";
import { getProfile, getClubActivitieList, postProfile } from "./api/Api";
import { ClubActivityMasterType } from "../../../../types/master/ClubActivityMasterType";
import { isCardEditing, isInputEditing } from "pages/common/LayoutFunction";
import { IsCardEditable } from "../../../common/IsEditable";
import {
  handleGetResponse,
  handlePostResponse,
} from "components/handleResponse";
import ToastMsg from "components/ToastMsg";

const Profiles = (emp: EmpType) => {
  /* 状態管理パラメータ */
  // 編集状態を管理するフラグ（true：編集中、false：非編集中）
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // データを保存したかを管理するフラグ
  const [isSave, setIsSave] = useState<boolean>(false);

  /* DB値 */
  const [profileData, setProfile] = useState<ProfileType>();
  const [clubActivityData, setClubActivity] =
    useState<ClubActivityMasterType[]>();

  /* 画面表示項目 */
  const [inputHobby, setHobby] = useState(""); // 趣味
  const [inputClubIds, setClubIds] = useState<number[]>([]); // 部活(IDで選択状態を保持)
  const [inputOthers, setOthers] = useState(""); // その他

  /**
   * DB値の反映処理
   * @param profileData プロフィールデータ
   */
  const dbDataToInputValues = (profileData: ProfileType) => {
    // 入力項目に展開
    setHobby(profileData.hobby ?? "");
    setOthers(profileData.others ?? "");
    setClubIds(profileData.clubActivityId ?? []);
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    // リロード対象の場合、取得APIを呼び出す
    (async () => {
      await handleGetResponse(getProfile(emp.empDataId)).then((data) => {
        dbDataToInputValues(data);
        setProfile(data);
        setIsSave(false);
      });
    })();
  }, []);

  /**
   * 部活のマスタテーブルを取得
   */
  useEffect(() => {
    (async () => {
      await handleGetResponse(getClubActivitieList()).then((data) => {
        setClubActivity(data);
      });
    })();
  }, []);

  /**
   * 編集アイコンクリックハンドラー
   */
  const onClickEditHandler = () => {
    setIsEdit(true);
  };

  /**
   * 「キャンセル」ボタンクリックハンドラー
   */
  const onClickInputClearHandler = () => {
    if (profileData) {
      dbDataToInputValues(profileData);
    }
    setIsEdit(false);
  };

  /**
   * 「保存」ボタンクリックハンドラー
   */
  const onClickButtonSaveHandler = () => {
    if (profileData) {
      // 保存押下時に更新APiを起動
      const inputValueAllProfile: ProfileType = {
        dataId: profileData?.dataId,
        empDataId: emp.empDataId,
        clubActivityId: inputClubIds,
        hobby: inputHobby,
        others: inputOthers,
        updatedAt: profileData.updatedAt,
      };

      (async () => {
        await handlePostResponse(postProfile(inputValueAllProfile))
          .then((data) => {
            setProfile(data);
            dbDataToInputValues(data);
            setIsSave(true);
          })
          .catch((error) => {
            console.error("POSTリクエストでエラーが発生しました。");
          });
      })();
    }
    setIsEdit(false);
  };

  return (
    <div style={isCardEditing(isEdit)}>
      {profileData && clubActivityData && (
        <>
          <BasicInfoCardHeader
            headerTitle="プロフィール"
            onClickEditHandler={() => onClickEditHandler()}
            iconType="accountBox"
            editable={IsCardEditable(emp.isEmployed)}
          />
          <CardContent>
            <Box sx={{ mt: 2 }}>
              <TextField
                sx={[isInputEditing(isEdit)]}
                size="small"
                label="趣味"
                InputProps={{ readOnly: !isEdit }}
                value={inputHobby}
                onChange={(e) => {
                  setHobby(e.target.value);
                }}
              />
            </Box>
            <FormControl sx={{ mt: 2 }}>
              <Autocomplete
                readOnly={!isEdit}
                sx={[{ width: 460 }, isInputEditing(isEdit)]}
                multiple
                options={clubActivityData}
                getOptionLabel={(option) => option.clubName}
                renderInput={(params) => (
                  <TextField {...params} label="部活" variant="outlined" />
                )}
                disableCloseOnSelect
                value={clubActivityData.filter((club) =>
                  inputClubIds.includes(club.clubId)
                )}
                onChange={(event, value) => {
                  setClubIds(value.map((club) => club.clubId));
                }}
              />
            </FormControl>
            <TextField
              id="others"
              label="その他"
              multiline
              rows={4}
              InputProps={{ readOnly: !isEdit }}
              sx={[{ width: 460, mt: 2 }, isInputEditing(isEdit)]}
              value={inputOthers}
              onChange={(e) => {
                setOthers(e.target.value);
              }}
            />
            <BasicFooterButton
              isEditing={isEdit}
              mright={1}
              onClickSave={onClickButtonSaveHandler}
              onClickCancel={onClickInputClearHandler}
            />
            <ToastMsg isDisp={isSave} isSuccess={true} />
          </CardContent>
        </>
      )}
    </div>
  );
};

export default Profiles;
