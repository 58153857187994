import { SummaryType } from "types/summary/SummaryType";
import { AnnualIncomesType } from "types/summary/AnnualIncomesType";
import apiClient from "components/apiClient";
import { GradeMasterType } from "types/master/GradeMasterType";
import { CruiseHistoryType } from "types/summary/CruiseHistoryType";

/**
 * 等級マスタ取得API
 * @returns 等級リスト
 */
export const getGradeList = async () => {
  return await apiClient.get<GradeMasterType>(
    `${process.env.REACT_APP_HOST}/master/getGradeMaster`
  );
};

/**
 * 年収情報取得API
 * @param empDataId 社員データID
 * @returns 社員一覧
 */
export const getAnnualIncomes = async (empDataId: number) => {
  return await apiClient.get<AnnualIncomesType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/annualIncome`
  );
};

/**
 * 年収情報更新API
 * @param data 年収情報DTO
 * @returns 年収情報
 */
export const postAnnualIncomes = async (data: AnnualIncomesType) => {
  return await apiClient.post(
    `${process.env.REACT_APP_HOST}/employees/annualIncome`,
    data
  );
};

/**
 * 評価・年収取得API
 * @param empDataId 社員データID
 * @returns 評価年収情報
 */
export const getSummary = async (empDataId: number) => {
  return await apiClient.get<SummaryType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/annualIncome`
  );
};

/**
 * 巡航管理情報取得API
 * @param empDataId 社員データID
 * @returns 巡航管理情報
 */
export const getCruiseInfo = async (empDataId: number) => {
  return await apiClient.get<CruiseHistoryType>(
    `${process.env.REACT_APP_HOST}/employees/${empDataId}/cruise`
  );
};

/**
 * 巡航管理情報更新API
 * @param data 巡航管理情報DTO
 * @returns 巡航管理情報
 */
export const postCruiseInfo = async (data: CruiseHistoryType) => {
  return await apiClient.post(
    `${process.env.REACT_APP_HOST}/employees/cruise`,
    data
  );
};
