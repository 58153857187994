import { styled } from "@mui/material";

/**
 * スタイルを返却する共通関数
 *
 * @param isEdit 編集フラグ
 * @param defaultBgColor 非編集時背景色
 * @param changeBgColor 編集時背景色
 * @returns スタイル
 */
const isEditing = (
  isEdit: boolean,
  defaultBgColor: string,
  changeBgColor: string
) => {
  return {
    backgroundColor: isEdit ? changeBgColor : defaultBgColor,
    height: "100%",
  };
};

/**
 * カードのスタイルを返却する関数
 *
 * @param isEdit 編集フラグ
 * @param changeBgColor 編集時背景色（デフォルト：#fbfced）
 * @returns カードスタイル
 */
export const isCardEditing = (
  isEdit: boolean,
  changeBgColor: string = "#fbfced"
) => {
  const defaultBgColor = "white";
  return isEditing(isEdit, defaultBgColor, changeBgColor);
};

/**
 * 入力可能項目のスタイルを返却する関数
 *
 * @param isEdit 編集フラグ
 * @param changeBgColor 編集時背景色（デフォルト：white）
 * @returns 入力項目のスタイル
 */
export const isInputEditing = (
  isEdit: boolean,
  changeBgColor: string = "white"
) => {
  const defaultBgColor = "#ebebeb";
  return isEditing(isEdit, defaultBgColor, changeBgColor);
};

/**
 * 必須項目のスタイルを返却する関数(TextFieldタグ用)
 */
export const isRequiredItem = styled("label")(({ theme }) => ({
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
}));

/**
 * 必須項目のスタイルを返却する関数(InputLabel(Select)タグ用)
 */
export const selectRequiredItem = {
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
};
