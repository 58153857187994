import { Box, CardHeader, IconButton, Typography } from "@mui/material";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BadgeIcon from "@mui/icons-material/Badge";
import CampaignIcon from "@mui/icons-material/Campaign";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import EditIcon from "@mui/icons-material/Edit";
import FlagIcon from "@mui/icons-material/Flag";
import GroupsIcon from "@mui/icons-material/Groups";
import HailIcon from "@mui/icons-material/Hail";
import LockIcon from "@mui/icons-material/Lock";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import PersonIcon from "@mui/icons-material/Person";

type basicInfoIconField = {
  headerTitle: string;
  iconType: string;
};
const BasicInfoIconField = ({ headerTitle, iconType }: basicInfoIconField) => {
  const headerIcon = (iconType: string) => {
    // アイコンはこちらで確認→https://mui.com/material-ui/material-icons/
    switch (iconType) {
      case "hail":
        return <HailIcon />;
      case "group":
        return <GroupsIcon />;
      case "note":
        return <NoteAltIcon />;
      case "accountBox":
        return <AccountBoxIcon />;
      case "run":
        return <DirectionsRunIcon />;
      case "campaign":
        return <CampaignIcon />;
      case "saiyou":
        return <BadgeIcon />;
      case "flag":
        return <FlagIcon />;
      case "workLog":
        return <AccessTimeFilledIcon />;
      default:
        return <PersonIcon />;
    }
  };
  return (
    <>
      <Box display="flex">
        {headerIcon(iconType)}
        <Typography sx={{ ml: 0.5 }}>{headerTitle}</Typography>
      </Box>
    </>
  );
};

type basicInfoCardHeader = {
  headerTitle: string;
  onClickEditHandler?: () => void;
  iconType: string;
  editable?: boolean;
};
const BasicInfoCardHeader = ({
  headerTitle,
  onClickEditHandler = () => {},
  iconType,
  editable = true,
}: basicInfoCardHeader) => {
  const onClickLockHandler = () => {};
  return (
    <CardHeader
      title={
        <BasicInfoIconField headerTitle={headerTitle} iconType={iconType} />
      }
      action={
        editable ? (
          <>
            <IconButton onClick={() => onClickEditHandler()}>
              <EditIcon color="primary" sx={{ fontSize: 18 }} />
            </IconButton>
            <IconButton onClick={() => onClickLockHandler()}>
              <LockIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </>
        ) : (
          <></>
        )
      }
    />
  );
};

export default BasicInfoCardHeader;
