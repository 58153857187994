import { useEffect, useState } from "react";
import BasicInfoCardHeader from "pages/common/BasicInfoCardHeader";
import { TextField } from "@mui/material";
import { EmpType } from "types/EmpType";
import { getPersonNearJoin } from "./api/Api";
import { PersonNearJoinningType } from "types/relationship/PersonNearJoinningType";
import { isInputEditing, isRequiredItem } from "pages/common/LayoutFunction";
import { handleGetResponse } from "components/handleResponse";

const PersonNearJoinning = (emp: EmpType) => {
  const [inputPersonNearJoinning, setPersonNearJoinning] = useState<
    PersonNearJoinningType[]
  >([]);
  const noData = "メンバーがいません";

  useEffect(() => {
    (async () => {
      await handleGetResponse(getPersonNearJoin(emp.empDataId)).then((data) => {
        setPersonNearJoinning(data);
      });
    })();
  }, []);

  return (
    <div>
      <BasicInfoCardHeader
        headerTitle="入社時期が近い人"
        iconType="hail"
        editable={false}
      />
      <TextField
        multiline
        id="searchName"
        label="名前"
        rows={Math.min(
          inputPersonNearJoinning.length === 0
            ? 1
            : inputPersonNearJoinning.length,
          3
        )}
        InputProps={{ readOnly: true }}
        /* 取得してきた値が空だった場合、「メンバーがいません」と返す */
        value={
          inputPersonNearJoinning.length === 0
            ? noData
            : inputPersonNearJoinning
        }
        sx={[{ ml: 2, mt: 2, width: "260px" }, isInputEditing(false)]}
      />
    </div>
  );
};

export default PersonNearJoinning;
