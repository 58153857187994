import { Auth } from 'aws-amplify';
import { NavigateFunction } from 'react-router-dom';

/**
 * 認証されているかをチェックする関数
 * - 認証されている場合、認証情報を返却する
 * - 認証されていない場合、ログインページに遷移する
 * 
 * @param navigator ナビゲータ
 * @param location ロケーション情報
 * @returns 認証された情報
 */
export async function checkAuthenticated(navigator: NavigateFunction, location?: any) {
    try {
        if (location && !location.state) {
            // ロケーションにstateの情報が保持されていない場合、エラー
            throw Error("Not have session information")
        }

        // 認証済みのユーザを取得する
        const user = await Auth.currentAuthenticatedUser();
        // 現在のセッション情報を取得する(トークンの期限が切れている場合、自動的に更新)
        const session = await Auth.currentSession();
        const idToken = session.getIdToken();

        return {
            email: user.attributes.email,
            idToken: idToken.getJwtToken(),
        }
    } catch (error) {
        console.error('Not Authenticated');
        navigator('/');
    }
}

/**
 * トークンIDを取得する関数
 * - 有効期限切れの場合、新しく発行して返却
 * @returns トークンID
 */
export async function getCurrentIdToken() {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
}