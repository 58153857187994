import { AxiosResponse, HttpStatusCode } from "axios";

/**
 * エラーハンドリング処理
 * @param errorMsg エラーメッセージ
 */
const handleRequestError = (errorMsg: string) => {
  window.dispatchEvent(
    new CustomEvent("requestError", {
      detail: errorMsg,
    })
  );
};

/**
 * GETリクエスト処理
 * @param getFunction GET関数
 * @returns レスポンス値
 */
export const handleGetResponse = async (
  getFunction: Promise<AxiosResponse<any, any>>
) => {
  try {
    const { status, data } = await getFunction;
    if (status === HttpStatusCode.Ok) {
      return data;
    }
  } catch (error: any) {
    if (error.response.status === HttpStatusCode.NotFound) {
      handleRequestError(error.response.data);
    } else if (error.response.status === HttpStatusCode.Forbidden) {
      handleRequestError("アクセス権限がありません。");
    } else {
      handleRequestError("予期せぬエラーが発生しました。");
    }
    throw Error("GETリクエストでエラーが発生しました。");
  }
};

/**
 * POSTリクエスト処理
 * @param postFunction POST関数
 */
export const handlePostResponse = async (
  postFunction: Promise<AxiosResponse<any, any>>
) => {
  try {
    const { status, data } = await postFunction;
    if (status === HttpStatusCode.Ok) {
      return data;
    }
  } catch (error: any) {
    if (
      error.response.status === HttpStatusCode.BadRequest ||
      error.response.status === HttpStatusCode.NotFound ||
      error.response.status === HttpStatusCode.Conflict
    ) {
      handleRequestError(error.response.data);
    } else if (error.response.status === HttpStatusCode.Forbidden) {
      handleRequestError("アクセス権限がありません。");
    } else {
      handleRequestError("予期せぬエラーが発生しました。");
    }
    throw Error("POSTリクエストでエラーが発生しました。");
  }
};
