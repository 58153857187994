import React from "react";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

import { Auth } from "aws-amplify";

type headerProps = {
  title: string;
  username?: string;
};
const Header = ({ title, username }: headerProps) => {
  /**
   * ログアウト処理
   */
  const handleLogout = async () => {
    await Auth.signOut()
      .then(() => {
        console.log("ログアウトしました");
      })
      .catch((error) => {
        console.error("ログアウトに失敗しました", error);
      });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {username && (
            <>
              <Typography variant="body1" sx={{ marginRight: 2 }}>
                {username}
              </Typography>
              <IconButton onClick={handleLogout} sx={{ color: "white" }}>
                <LogoutIcon />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
