import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { EmpType } from "types/EmpType";
import { SummaryType } from "types/summary/SummaryType";
import {
  getSummary,
  getAnnualIncomes,
  postAnnualIncomes,
  getGradeList,
  getCruiseInfo,
  postCruiseInfo,
} from "./api/Api";
import BasicInfoCardHeader from "pages/common/BasicInfoCardHeader";
import IncomeLineGraph from "components/IncomeLineGraph";
import { AnnualIncomesType } from "types/summary/AnnualIncomesType";
import { ViewIncomeType } from "types/summary/ViewIncomType";
import {
  isCardEditing,
  isInputEditing,
  isRequiredItem,
  selectRequiredItem,
} from "pages/common/LayoutFunction";
import BasicFooterButton from "pages/common/BasicFooterButton";
import { IsCardEditable } from "../../common/IsEditable";
import {
  handleGetResponse,
  handlePostResponse,
} from "components/handleResponse";
import { GradeMasterType } from "types/master/GradeMasterType";
import ToastMsg from "components/ToastMsg";
import { CruiseHistoryType } from "types/summary/CruiseHistoryType";
import { CruiseInfoType } from "types/summary/CruiseInfoType";
import { convertFiscalYear, getFiscalYear, getSemester } from "pages/common/DateUtils";
import Modal from "./Modal";
import { Semester } from "constants/Enum";
import { useLocation } from "react-router-dom";

const Summary = (emp: EmpType) => {

  // URL情報
  const location = useLocation();

  /** 状態管理パラメーター */
  //編集状態を管理するフラグ(true:編集中、false:非編集中)
  const [isEdit, setIsEdit] = useState(false);
  // データを保存したかを管理するフラグ
  const [isSave, setIsSave] = useState<boolean>(false);
  /** DB値 */
  const [annualIncomesData, setAnnualIncomes] = useState<AnnualIncomesType>();
  const [summaryData, setSummary] = useState<SummaryType>();
  const [gradeList, setGradeList] = useState<GradeMasterType>();
  const [cruiseParent, setCruiseParent] = useState<CruiseHistoryType>();

  /** 画面表示項目 */
  const [inputBasicSalary, setBasicSalary] = useState(0); //月収
  const [inputGradeId, setGradeId] = useState(0); //等級
  const [inputPreviousIncome, setPreviousIncome] = useState<number | null>(
    null
  ); //前職年収
  const [inputInitialIncome, setInitialIncome] = useState<number | null>(null); //入社時年収
  const [inputFutureIncomeManager, setFutureIncomeManager] = useState<
    number | null
  >(null); //5年後年収（上長）
  const [inputFutureIncomeEmployee, setFutureIncomeEmployee] = useState<
    number | null
  >(null); //5年後年収（本人）
  const [inputRetirementIncome, setRetirementIncome] = useState<number | null>(
    null
  ); //退職時年収
  const [inputLayer, setLayer] = useState(""); //レイヤー
  const [inputEsop, setEsop] = useState<number | null>(null); //ESOP
  const formatAmount = 10000; // 万円表記処理用のフォーマット

  const [cruiseCheck, setCruiseCheck] = useState<boolean>(false);

  // 巡航管理情報履歴フラグ(true:チェックあり、false:チェックなし)
  const [cruiseFlg, setCruiseFlg] = useState<boolean>(false);

  // Modal flag
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  /**
   * 巡航履歴の表示開始年度を返却します
   * 
   * 最も古い履歴が2021年度のため、2021年度以前に入社している人の場合は2021年度から表示させる
   * 
   * @param startEmpString 入社年月
   * @returns 巡航履歴の表示開始年度
   */
  const cruiseStartFiscalYear = (startEmpString: String) => {
    // 巡航履歴を管理しているもっとも古い年度
    const oldestFiscalYear = 2021;
    if (!startEmpString) {
      // 社員の情報が取れない場合は、描画できなくなるため最も古い年度を返す
      return oldestFiscalYear;
    }
    const startEmpArray = startEmpString.split('-');
    const empStartFiscalYear = convertFiscalYear(Number(startEmpArray[0]), Number(startEmpArray[1]));
    // 履歴を取り始めた最も古い年度と入社年度を比較し、新しいほうを表示開始年度として返却
    return Math.max(oldestFiscalYear, empStartFiscalYear);
  }


  /**
   * 巡航履歴情報のHTML要素を返却します
   * 
   * @returns 巡航履歴情報のHTML情報
   */
  const CruiseTable = () => {
    // 現在の年度を取得する
    const currentFiscalYear = getFiscalYear();
    // 巡航履歴を表示する開始年度を取得する
    const startFiscalYear = cruiseStartFiscalYear(String(emp.employStartDate));

    // 出力する年度は入社年度（もしくは履歴を保持している最も古い年度）から現在年度まで
    const outputCount = currentFiscalYear - startFiscalYear + 1;
    const pastYears = Array.from({ length: outputCount }, (_, i) => currentFiscalYear - i);

    const groupedData = (cruiseParent?.cruiseHistory || []).reduce((acc, item) => {
      if (!acc[item.annual]) acc[item.annual] = { upperHalf: null, lowerHalf: null };
      if (item.semiannual === Semester.FIRST) acc[item.annual].upperHalf = item;
      if (item.semiannual === Semester.SECOND) acc[item.annual].lowerHalf = item;
      return acc;
    }, {} as Record<number, { upperHalf: CruiseInfoType | null; lowerHalf: CruiseInfoType | null }>);

    const displayData = pastYears.reduce((acc, year) => {
      acc[year] = groupedData[year] || { upperHalf: null, lowerHalf: null };
      return acc;
    }, {} as Record<number, { upperHalf: CruiseInfoType | null, lowerHalf: CruiseInfoType | null }>)

    return (
      <table width="300px" style={{ borderCollapse: 'collapse', margin: 'auto' }}>
      <thead>
        <tr>
          <th style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid black' }}>年度</th>
          <th style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid black' }}>上期</th>
          <th style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid black' }}>下期</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(displayData).map(([annual, data]) => (
          <tr key={annual}>
            <td style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid black' }}>{annual}</td>
            <td style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid black' }}>
              {data.upperHalf ? '巡航' : '-'}
            </td>
            <td style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid black' }}>
              {data.lowerHalf ? '巡航' : '-'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    )
  }


  // const CruiseTable = () => {
  //   const groupedData = cruiseParent?.cruiseHistory?.reduce((acc, item) => {
  //     if (!acc[item.annual]) acc[item.annual] = { upperHalf: null, lowerHalf: null };
  //     if (item.semiannual === 1) acc[item.annual].upperHalf = item;
  //     if (item.semiannual === 2) acc[item.annual].lowerHalf = item;
  //     return acc;
  //   }, {} as Record<number, { upperHalf: CruiseInfoType | null; lowerHalf: CruiseInfoType | null }>);

  //   return (
  //     <table width={"300px"} style={{ borderCollapse: 'collapse', margin: 'auto' }}>
  //       <thead>
  //         <tr>
  //           <th style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid black' }}>年度</th>
  //           <th style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid black' }}>上期</th>
  //           <th style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid black' }}>下期</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {groupedData && Object.entries(groupedData).map(([annual, data]) => (
  //           <tr key={annual}>
  //             <td style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid black'}}>{annual}</td>
  //             <td style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid black'}}>
  //               {data.upperHalf ? '巡航' : '-'}
  //             </td>
  //             <td style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid black'}}>
  //               {data.lowerHalf ? '巡航' : '-'}
  //             </td>
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   )
  // }

  /**
   * DB値の反映処理
   *
   * @param annualIncomesData
   */
  const dbDataToInputValues = (annualIncomesData: AnnualIncomesType) => {
    //入力項目に展開
    setBasicSalary(manChangeAmount(annualIncomesData.basicSalary));
    setGradeId(annualIncomesData.gradeId ?? "");
    setPreviousIncome(
      annualIncomesData.previousIncome != null
        ? manChangeAmount(annualIncomesData.previousIncome)
        : null
    );
    setInitialIncome(
      annualIncomesData.initialIncome != null
        ? manChangeAmount(annualIncomesData.initialIncome)
        : null
    );
    setFutureIncomeManager(
      annualIncomesData.futureIncomeManager != null
        ? manChangeAmount(annualIncomesData.futureIncomeManager)
        : null
    );
    setFutureIncomeEmployee(
      annualIncomesData.futureIncomeEmployee != null
        ? manChangeAmount(annualIncomesData.futureIncomeEmployee)
        : null
    );
    setRetirementIncome(
      annualIncomesData.retirementIncome != null
        ? manChangeAmount(annualIncomesData.retirementIncome)
        : null
    );
    setLayer(annualIncomesData.layer ?? "");
    setEsop(annualIncomesData.esop ?? null);
  };

  /**
   * DB値の反映処理(巡航管理フラグ)
   *
   * @param cruiseParent
   */

  const dbDataReflectValues = (cruiseParent: CruiseHistoryType) => {
    // 入力項目に展開
    setCruiseFlg(cruiseParent.latestCruise?.cruiseFlg ?? false);
  };

  /**
   * 初期処理
   */
  // summary上部は編集不可のため、初回のみデータ取得する
  useEffect(() => {
    (async () => {
      await handleGetResponse(getSummary(emp.empDataId)).then((data) => {
        setSummary(data);
      });
    })();

    console.log('test: ', location.state.loginUserInfo.dataId);

    // 等級マスタ取得
    (async () => {
      await handleGetResponse(getGradeList()).then((data) => {
        setGradeList(data);
      });
    })();
  }, []);

  // summary下部年収情報は編集可のため、保存・更新のたびにデータ取得する
  useEffect(() => {
    (async () => {
      await handleGetResponse(getAnnualIncomes(emp.empDataId)).then((data) => {
        setAnnualIncomes(data);
        dbDataToInputValues(data);
        setIsSave(false);
      });
    })();
  }, []);

  /**
   * 初期処理
   */
  useEffect(() => {
    // リロード対象の場合、取得APIを呼び出す
    // 本人の場合閲覧しない
    if (emp.empDataId !== location.state.loginUserInfo.dataId) {
      setCruiseCheck(true);
      (async () => {
        await handleGetResponse(getCruiseInfo(emp.empDataId)).then((data) => {
          setCruiseParent(data);
          dbDataReflectValues(data);
          setIsSave(false);
        });
      })();
    } else {
      setCruiseCheck(false);
    }
  }, []);

  /**
   * 編集アイコンクリックハンドラー
   */
  const onClickEditHandler = () => {
    setIsEdit(true);
  };

  // バリデーション用のオブジェクト、メソッドを定義
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<AnnualIncomesType>();

  //モーダルダイアログの開閉を管理するための関数
  const handleClose = () => {
    reset();
  };

  /**
   * 「キャンセル」ボタンクリックハンドラー
   */
  const onClickInputClearHandler = () => {
    if (annualIncomesData) {
      dbDataToInputValues(annualIncomesData);
    }
    if (cruiseParent) {
      dbDataReflectValues(cruiseParent);
    }
    reset(); // バリデーションメッセージのリセット
    setIsEdit(false);
  };

  // 万円表記⇒円表記処理
  const yenChangeAmount = (manChangeAmount: number) => {
    return manChangeAmount * formatAmount;
  };

  // 円表記⇒万円表記処理
  const manChangeAmount = (yenChangeAmount: number) => {
    return yenChangeAmount / formatAmount;
  };


  /**
   * 「保存」ボタンクリックハンドラー
   */
  const onClickButtonSaveHandler = () => {
    if (annualIncomesData) {
      // 保存押下時に更新APiを起動// axios通信で引き渡すデータ
      const inputAnnualIncomes = {
        dataId: annualIncomesData?.dataId,
        empDataId: emp.empDataId,
        basicSalary: yenChangeAmount(inputBasicSalary),
        gradeId: inputGradeId,
        previousIncome: inputPreviousIncome
          ? yenChangeAmount(inputPreviousIncome)
          : null,
        initialIncome: yenChangeAmount(Number(inputInitialIncome)), // 必須項目のため数値のみを値に持つが、型定義がnumber | nullのため明示的にNumber型にキャスト
        futureIncomeManager: inputFutureIncomeManager
          ? yenChangeAmount(inputFutureIncomeManager)
          : null,
        futureIncomeEmployee: inputFutureIncomeEmployee
          ? yenChangeAmount(inputFutureIncomeEmployee)
          : null,
        retirementIncome: yenChangeAmount(Number(inputRetirementIncome)), // 必須項目のため数値のみを値に持つが、型定義がnumber | nullのため明示的にNumber型にキャスト
        layer: inputLayer,
        esop: Number(inputEsop), // 必須項目のため数値のみを値に持つが、型定義がnumber | nullのため明示的にNumber型にキャスト
        incomeHistories: [],
        updatedAt: annualIncomesData.updatedAt,
      };
      (async () => {
        await handlePostResponse(postAnnualIncomes(inputAnnualIncomes))
          .then((data) => {
            setAnnualIncomes(data);
            dbDataToInputValues(data);
            setIsSave(true);
          })
          .catch((error) => {
            console.error("POSTリクエストでエラーが発生しました。");
          });
      })();
    }
    if (cruiseParent) {
      if (cruiseFlg !== cruiseParent.latestCruise?.cruiseFlg && cruiseCheck) {
        // 更新リクエスト時のパラメータを設定する
        const inputCruiseInfo: CruiseInfoType = {
          dataId: cruiseParent.latestCruise?.dataId,
          empDataId: emp.empDataId,
          annual: cruiseParent.latestCruise?.annual ?? getFiscalYear(),
          semiannual: cruiseParent.latestCruise?.semiannual ?? getSemester(),
          cruiseFlg: cruiseFlg,
          updatedAt: cruiseParent.latestCruise?.updatedAt ?? null,
        };
        const inputCruiseParent: CruiseHistoryType = {
          empDataId: emp.empDataId,
          latestCruise: inputCruiseInfo,
        };
        (async () => {
          await handlePostResponse(postCruiseInfo(inputCruiseParent))
            .then((data) => {
              setCruiseParent(data);
              dbDataReflectValues(data);
              setIsSave(true);
            })
            .catch((error) => {
              console.error("POSTリクエストでエラーが発生しました。");
            });
        })();
      }
    }
    setIsEdit(false);
  };

  // グラフ作成用データの作成
  const creatViewDatas = (income: AnnualIncomesType): ViewIncomeType => {
    // グラフ作成用データ
    const viewIncome: ViewIncomeType = {
      arrDate: [],
      annualIncome: [],
      futureIncomeManager: [],
      futureIncomeEmployee: [],
      previousIncome: [],
    };

    // 履歴がある場合、表示用のオブジェクトを設定する
    if (income.incomeHistories.length > 0) {
      income.incomeHistories.map((dateByIncome) => {
        if (dateByIncome.date) {
          // String型→Date型に変換
          let dateObject = new Date(dateByIncome.date);
          viewIncome.arrDate.push(dateObject);
          viewIncome.annualIncome.push(dateByIncome.annualIncome);
          viewIncome.futureIncomeManager.push(dateByIncome.futureIncomeManager);
          viewIncome.futureIncomeEmployee.push(
            dateByIncome.futureIncomeEmployee
          );
          viewIncome.previousIncome.push(income.previousIncome ?? 0);
        }
      });
    }

    // カレントのレコードがある場合、表示用のオブジェクトを設定する
    if (income.empDataId != null) {
      const nowDate = new Date();
      // 年を取得
      const year = nowDate.getFullYear();
      // 月を取得
      const month = nowDate.getMonth() + 1;
      // 年度を取得
      const fiscalYear = month >= 4 ? year : year - 1;
      const nowFiscalYear = new Date(fiscalYear, 0, 1);
      viewIncome.arrDate.push(nowFiscalYear);
      viewIncome.annualIncome.push(income.basicSalary * 13);
      viewIncome.futureIncomeManager.push(income.futureIncomeManager ?? 0);
      viewIncome.futureIncomeEmployee.push(income.futureIncomeEmployee ?? 0);
      viewIncome.previousIncome.push(income.previousIncome ?? 0);
    }

    return viewIncome;
  };

  return (
    <div>
      {summaryData && annualIncomesData && (cruiseCheck ? cruiseParent : true) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignitems: "center",
            width: "1600px",
          }}
        >
          <Box width="800px" style={isCardEditing(isEdit)}>
            <BasicInfoCardHeader
              headerTitle="Summary"
              onClickEditHandler={() => onClickEditHandler()}
              iconType="campaign"
              editable={IsCardEditable(emp.isEmployed)}
            />
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "750px",
                }}
              >
                <TextField
                  size="small"
                  label="新年収"
                  sx={{ backgroundColor: "#FEE" }}
                  value={
                    summaryData.newAnnualIncome
                      ? manChangeAmount(summaryData.newAnnualIncome) + "万円"
                      : ""
                  }
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  size="small"
                  label="UP額"
                  sx={{
                    backgroundColor: "#FEE",
                    ml: 1,
                    color: "error",
                  }}
                  value={
                    summaryData.upAmount
                      ? manChangeAmount(summaryData.upAmount) + "万円"
                      : ""
                  }
                  InputProps={{ readOnly: true }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "750px",
                  mt: 5,
                }}
              >
                <TextField
                  size="small"
                  label="（前回）"
                  sx={{ backgroundColor: "#FEE", width: "15%" }}
                  value={
                    summaryData.previousUpAmount
                      ? manChangeAmount(summaryData.previousUpAmount) + "万円"
                      : ""
                  }
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  size="small"
                  label="（傾き）"
                  sx={{ backgroundColor: "#EFE", width: "15%", ml: 1 }}
                  value={summaryData.slope}
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  size="small"
                  label="現年収ランク"
                  sx={{ backgroundColor: "#F7F7F7", ml: 1 }}
                  value={summaryData.currentAnnualIncomeRank}
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  size="small"
                  label="（年収上位）"
                  sx={{ backgroundColor: "#F7F7F7", width: "20%", ml: 1 }}
                  value={
                    summaryData.annualIncomeUpper
                      ? summaryData.annualIncomeUpper + "%"
                      : ""
                  }
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  size="small"
                  label="評価回数"
                  sx={{ backgroundColor: "#F7F7F7", width: "15%", ml: 1 }}
                  value={
                    summaryData.evaluationTimes
                      ? summaryData.evaluationTimes + "回"
                      : ""
                  }
                  InputProps={{ readOnly: true }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "750px",
                  mt: 6,
                }}
              >
                <TextField
                  size="small"
                  label="4色タイプ"
                  sx={{ backgroundColor: "#F7F7F7", width: "20%" }}
                  value={summaryData.fourColorType}
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  size="small"
                  label="直近案件"
                  sx={{ backgroundColor: "#F7F7F7", width: "20%", ml: 1 }}
                  value={summaryData.lastProject}
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  size="small"
                  label="判定単価"
                  sx={{ backgroundColor: "#F7F7F7", width: "20%", ml: 1 }}
                  value={
                    summaryData.decisionUnitPrice
                      ? summaryData.decisionUnitPrice + "万円"
                      : ""
                  }
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  size="small"
                  label="半期売上"
                  sx={{ backgroundColor: "#F7F7F7", width: "20%", ml: 1 }}
                  value={
                    summaryData.halfYearSales
                      ? manChangeAmount(summaryData.halfYearSales) + "万円"
                      : ""
                  }
                  InputProps={{ readOnly: true }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "750px",
                  mt: 2,
                }}
              >
                <TextField
                  size="small"
                  label="（想定年収）"
                  sx={{ backgroundColor: "#F7F7F7", width: "20%" }}
                  value={
                    summaryData.assumptionAnnualIncome
                      ? manChangeAmount(summaryData.assumptionAnnualIncome) +
                        "万円"
                      : ""
                  }
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  size="small"
                  label="（成長率）"
                  sx={{ backgroundColor: "#F7F7F7", width: "20%", ml: 1 }}
                  value={
                    summaryData.grouwthRate ? summaryData.grouwthRate + "%" : ""
                  }
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  size="small"
                  label="（売総率）"
                  sx={{ backgroundColor: "#F7F7F7", width: "20%", ml: 1 }}
                  value={
                    summaryData.sellingTotalRate
                      ? summaryData.sellingTotalRate + "%"
                      : ""
                  }
                  InputProps={{ readOnly: true }}
                />
                {cruiseCheck && (
                  <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cruiseFlg}
                        onChange={() => setCruiseFlg(!cruiseFlg)}
                        sx={{ width: "20%", m: "auto" }}
                        disabled={!isEdit}
                      />
                    }
                    sx={{ width: "17%", m: "auto" }}
                    label="巡航情報追加"
                  />
                  <Button
                    variant="outlined"
                    sx={{ ml: 2, display: "flex" }}
                    onClick={() => setIsModalOpen(true)}
                  >
                    履歴
                  </Button>
                  <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                  >
                    <h2 style={{ textAlign: 'center' }}>巡航履歴</h2>
                    {CruiseTable()}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button variant="contained" sx={{ mt: 2 }} color="primary" onClick={() => setIsModalOpen(false)}>
                        閉じる
                      </Button>
                    </div>
                  </Modal>
                  </>
                  )}
              </Box>
              <Box
                sx={{
                  fontSize: "large",
                  fontWeight: "Bold",
                  textAlign: "center",
                  mt: 5,
                  mb: 5,
                }}
              >
                {summaryData.tag}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "750px",
                }}
              >
                <TextField
                  size="small"
                  label="月給"
                  type="number"
                  required
                  InputLabelProps={{ component: isRequiredItem }}
                  sx={[{ width: "20%" }, isInputEditing(false)]}
                  value={inputBasicSalary}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">万円</InputAdornment>
                    ),
                  }}
                />
                <FormControl sx={{ width: "20%", ml: 1 }}>
                  <InputLabel
                    size="small"
                    id="isCourseId"
                    required
                    sx={[selectRequiredItem]}
                  >
                    等級
                  </InputLabel>
                  <Select
                    size="small"
                    label="等級"
                    inputProps={{ readOnly: !isEdit }}
                    sx={[isInputEditing(isEdit)]}
                    value={inputGradeId}
                    // バリデーションチェック
                    {...register("gradeId", {
                      required: {
                        value: true,
                        message: "等級は必須項目です。",
                      },
                    })}
                    error={"gradeId" in errors}
                    onChange={(e) => {
                      setGradeId(Number(e.target.value));
                    }}
                  >
                    {gradeList?.map((grade) => (
                      <MenuItem key={grade.gradeId} value={grade.gradeId}>
                        {grade.gradeName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  size="small"
                  label="（前職年収）"
                  type="number"
                  sx={[{ width: "20%", ml: 1 }, isInputEditing(isEdit)]}
                  value={inputPreviousIncome ?? ""}
                  // バリデーションチェック
                  {...register("previousIncome", {
                    pattern: {
                      // 0以上の5桁の整数のみ入力可能とする正規表現
                      value: /^([1-9]\d{0,4}|0)$/,
                      message:
                        "前職年収は0以上の5桁以下の整数で入力してください。",
                    },
                  })}
                  error={"previousIncome" in errors}
                  onChange={(e) => {
                    setPreviousIncome(
                      e.target.value === "" ? null : Number(e.target.value)
                    );
                  }}
                  InputProps={{
                    readOnly: !isEdit,
                    endAdornment: (
                      <InputAdornment position="end">万円</InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "750px",
                  mt: 2,
                }}
              >
                <TextField
                  size="small"
                  label="入社時年収"
                  type="number"
                  sx={[{ width: "20%" }, isInputEditing(isEdit)]}
                  required
                  InputLabelProps={{ component: isRequiredItem }}
                  value={inputInitialIncome}
                  // バリデーションチェック
                  {...register("initialIncome", {
                    required: {
                      value: true,
                      message: "入社時年収は必須項目です。",
                    },
                    pattern: {
                      // 0以上の5桁の整数のみ入力可能とする正規表現
                      value: /^([1-9]\d{0,4}|0)$/,
                      message:
                        "入社時年収は0以上の5桁以下の整数で入力してください。",
                    },
                  })}
                  error={"initialIncome" in errors}
                  onChange={(e) => {
                    setInitialIncome(
                      e.target.value === "" ? null : Number(e.target.value)
                    );
                  }}
                  InputProps={{
                    readOnly: !isEdit,
                    endAdornment: (
                      <InputAdornment position="end">万円</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  size="small"
                  label="5年後年収（上長）"
                  type="number"
                  sx={[{ width: "20%", ml: 1 }, isInputEditing(isEdit)]}
                  value={inputFutureIncomeManager ?? ""}
                  // バリデーションチェック
                  {...register("futureIncomeManager", {
                    pattern: {
                      // 0以上の5桁の整数のみ入力可能とする正規表現
                      value: /^([1-9]\d{0,4}|0)$/,
                      message:
                        "5年後年収（上長）は0以上の5桁以下の整数で入力してください。",
                    },
                  })}
                  error={"futureIncomeManager" in errors}
                  onChange={(e) => {
                    setFutureIncomeManager(
                      e.target.value === "" ? null : Number(e.target.value)
                    );
                  }}
                  InputProps={{
                    readOnly: !isEdit,
                    endAdornment: (
                      <InputAdornment position="end">万円</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  size="small"
                  label="5年後年収（本人）"
                  type="number"
                  sx={[{ width: "20%", ml: 1 }, isInputEditing(isEdit)]}
                  value={inputFutureIncomeEmployee ?? ""}
                  // バリデーションチェック
                  {...register("futureIncomeEmployee", {
                    pattern: {
                      // 0以上の5桁の整数のみ入力可能とする正規表現
                      value: /^([1-9]\d{0,4}|0)$/,
                      message:
                        "5年後年収（本人）は0以上の5桁以下の整数で入力してください。",
                    },
                  })}
                  error={"futureIncomeEmployee" in errors}
                  onChange={(e) => {
                    setFutureIncomeEmployee(
                      e.target.value === "" ? null : Number(e.target.value)
                    );
                  }}
                  InputProps={{
                    readOnly: !isEdit,
                    endAdornment: (
                      <InputAdornment position="end">万円</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  size="small"
                  label="（退職時年収）"
                  type="number"
                  sx={[{ width: "20%", ml: 1 }, isInputEditing(isEdit)]}
                  required
                  InputLabelProps={{ component: isRequiredItem }}
                  value={inputRetirementIncome}
                  // バリデーションチェック
                  {...register("retirementIncome", {
                    required: {
                      value: true,
                      message: "（退職時年収）は必須項目です。",
                    },
                    pattern: {
                      // 0以上の5桁の整数のみ入力可能とする正規表現
                      value: /^([1-9]\d{0,4}|0)$/,
                      message:
                        "（退職時年収）は0以上の5桁以下の整数で入力してください。",
                    },
                  })}
                  error={"retirementIncome" in errors}
                  onChange={(e) => {
                    setRetirementIncome(
                      e.target.value === "" ? null : Number(e.target.value)
                    );
                  }}
                  InputProps={{
                    readOnly: !isEdit,
                    endAdornment: (
                      <InputAdornment position="end">万円</InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "750px",
                  marginBottom: 2,
                  mt: 2,
                }}
              >
                <TextField
                  size="small"
                  label="レイヤー"
                  sx={[isInputEditing(isEdit)]}
                  value={inputLayer}
                  onChange={(e) => {
                    setLayer(e.target.value);
                  }}
                  InputProps={{ readOnly: !isEdit }}
                />
                {(errors.basicSalary ||
                  errors.gradeId ||
                  errors.previousIncome ||
                  errors.initialIncome ||
                  errors.futureIncomeManager ||
                  errors.futureIncomeEmployee ||
                  errors.retirementIncome) && (
                  <Dialog open={true} onClose={handleClose}>
                    <DialogTitle>エラー</DialogTitle>
                    <DialogContent>
                      {errors.gradeId && (
                        <DialogContentText>
                          {errors.gradeId.message}
                        </DialogContentText>
                      )}
                      {errors.basicSalary && (
                        <DialogContentText>
                          {errors.basicSalary.message}
                        </DialogContentText>
                      )}
                      {errors.previousIncome && (
                        <DialogContentText>
                          {errors.previousIncome.message}
                        </DialogContentText>
                      )}
                      {errors.initialIncome && (
                        <DialogContentText>
                          {errors.initialIncome.message}
                        </DialogContentText>
                      )}
                      {errors.futureIncomeManager && (
                        <DialogContentText>
                          {errors.futureIncomeManager.message}
                        </DialogContentText>
                      )}
                      {errors.futureIncomeEmployee && (
                        <DialogContentText>
                          {errors.futureIncomeEmployee.message}
                        </DialogContentText>
                      )}
                      {errors.retirementIncome && (
                        <DialogContentText>
                          {errors.retirementIncome.message}
                        </DialogContentText>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} autoFocus>
                        閉じる
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
                <BasicFooterButton
                  isEditing={isEdit}
                  mtop={0}
                  mright={-66}
                  onClickSave={handleSubmit(onClickButtonSaveHandler)}
                  onClickCancel={onClickInputClearHandler}
                />
                <ToastMsg isDisp={isSave} isSuccess={true} />
              </Box>
            </Box>
          </Box>
          <Box>
            <IncomeLineGraph {...creatViewDatas(annualIncomesData)} />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Summary;
