import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import HumanrRsource from "./contents/HumanrRsource";
import Personal from "./contents/Personal";
import Humans from "../relationship/contents/Humans";
import Ffs from "./contents/Ffs";
import Profiles from "./contents/Profiles";
import HiringDetails from "./contents/HiringDetails";
import Career from "./contents/Career";
import ExternalActivities from "./contents/ExternalActivities";
import Memo from "./contents/Memo";
import Labor from "./contents/Labor";
import { EmpType } from "../../../types/EmpType";

const BasicInfo = (emp: EmpType) => {
  const cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    width: "1060px",
    variant: "outlined",
  };

  return (
    <Card
      style={cardStyle}
      sx={{ ml: 32, mt: 3, border: 1, borderColor: "#2AA8A8" }}
    >
      <CardHeader
        title="基本情報"
        sx={{ backgroundColor: "#2AA8A8", color: "white" }}
      />
      <CardContent>
        <div>
          <Box>
            <Grid container>
              <Grid item xs={6}>
                <Box>
                  <Card sx={{ width: "500px" }}>
                    <HumanrRsource
                      empDataId={emp.empDataId}
                      isEmployed={emp.isEmployed}
                    />
                  </Card>
                  <Card sx={{ mt: 2, width: "500px" }}>
                    <Ffs
                      empDataId={emp.empDataId}
                      isEmployed={emp.isEmployed}
                    />
                  </Card>
                  <Card sx={{ mt: 2, width: "500px" }}>
                    <HiringDetails
                      empDataId={emp.empDataId}
                      isEmployed={emp.isEmployed}
                    />
                  </Card>
                  <Card sx={{ mt: 2, width: "500px" }}>
                    <Career
                      empDataId={emp.empDataId}
                      isEmployed={emp.isEmployed}
                    />
                  </Card>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Card sx={{ ml: 2, width: "500px" }}>
                    <Personal
                      empDataId={emp.empDataId}
                      isEmployed={emp.isEmployed}
                    />
                  </Card>
                  <Card sx={{ ml: 2, mt: 2, width: "500px" }}>
                    <Profiles
                      empDataId={emp.empDataId}
                      isEmployed={emp.isEmployed}
                    />
                  </Card>
                  <Card sx={{ ml: 2, mt: 2, width: "500px" }}>
                    <ExternalActivities
                      empDataId={emp.empDataId}
                      isEmployed={emp.isEmployed}
                    />
                  </Card>
                  <Card sx={{ ml: 2, mt: 2, width: "500px" }}>
                    <Memo
                      empDataId={emp.empDataId}
                      isEmployed={emp.isEmployed}
                    />
                  </Card>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Card sx={{ mt: 2, width: "1030px" }}>
                <Labor empDataId={emp.empDataId} isEmployed={emp.isEmployed} />
              </Card>
            </Box>
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};

export default BasicInfo;
