import { useEffect, useState } from "react";
import BasicInfoCardHeader from "../../../common/BasicInfoCardHeader";
import BasicFooterButton from "../../../common/BasicFooterButton";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { EmpType } from "../../../../types/EmpType";
import { DirectivityType } from "../../../../types/challenging/DirectivityType";
import {
  getCareerCourseMaster,
  getDirectiviy,
  postDirectiviy,
} from "./api/Api";
import {
  isCardEditing,
  isInputEditing,
  selectRequiredItem,
} from "pages/common/LayoutFunction";
import { IsCardEditable } from "../../../common/IsEditable";
import {
  handleGetResponse,
  handlePostResponse,
} from "components/handleResponse";
import { CareerCourseMasterType } from "types/master/CareerCourseMasterType";
import ToastMsg from "components/ToastMsg";
import { SubmitHandler, useForm } from "react-hook-form";

const Directional = (emp: EmpType) => {
  /* 状態管理パラメータ */
  // 編集状態を管理するフラグ（true：編集中、false：非編集中）
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // データを保存したかを管理するフラグ
  const [isSave, setIsSave] = useState<boolean>(false);

  /* DB値 */
  const [directiviyData, setDirectiviyData] = useState<DirectivityType>();
  const [careerCourseMasterData, setCareerCourseMasterData] =
    useState<CareerCourseMasterType>();

  /* 画面表示項目 */
  // 成長イメージ（3年後）
  const [inputGrowsImageMidTermCode, setGrowImageMidTermCode] = useState(0);
  // 成長イメージ（4～6年後）
  const [inputGrowsImageLongTermCode, setGrowImageLongTermCode] = useState(0);
  // キャリア志向
  const [inputCourseId, setCourseId] = useState(0);
  // やりがい1番重視
  const [inputMostImportantRewardingCode, setMostImportantRewardingCode] =
    useState(0);
  // 働き方1番重視
  const [inputMostImportantWorkingCode, setMostImportantWorkingCode] =
    useState(0);
  // AWARDでGrabitしたい賞
  const [inputRewords, setRewords] = useState("");
  // タグ
  const [inputTag, setTag] = useState("");

  // バリデーション用のオブジェクト、メソッドを定義
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<DirectivityType>();

  // モーダルダイアログの開閉を管理するための関数
  const handleClose = () => {
    reset(); // フォームの値をリセット
  };

  /**
   * DB値の反映処理
   * @param dbDirectivity 指向性情報
   */
  const dbDataToInputValues = (dbDirectivity: DirectivityType) => {
    // 入力項目に展開
    setGrowImageMidTermCode(dbDirectivity.growsImageMidTermCode ?? "");
    setGrowImageLongTermCode(dbDirectivity.growsImageLongTermCode ?? "");
    setCourseId(dbDirectivity.courseId ?? "");
    setMostImportantRewardingCode(
      dbDirectivity.mostImportantRewardingCode ?? ""
    );
    setMostImportantWorkingCode(dbDirectivity.mostImportantWorkingCode ?? "");
    setRewords(dbDirectivity.rewards ?? "");
    setTag(dbDirectivity.tag ?? "");
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    (async () => {
      await handleGetResponse(getDirectiviy(emp.empDataId)).then((data) => {
        setDirectiviyData(data);
        dbDataToInputValues(data);
        setIsSave(false);
      });
    })();
  }, []);

  /**
   * キャリアコースマスター情報を取得
   **/
  useEffect(() => {
    (async () => {
      await handleGetResponse(getCareerCourseMaster()).then((data) => {
        setCareerCourseMasterData(data);
      });
    })();
  }, []);

  /**
   * 編集アイコンクリックハンドラー
   */
  const onClickEditHandler = () => {
    setIsEdit(true);
  };

  /**
   * 「保存」ボタンクリックハンドラー
   */
  const onClickButtonSaveHandler: SubmitHandler<DirectivityType> = () => {
    if (directiviyData) {
      // 更新リクエスト時のパラメータを設定する
      const directionals: DirectivityType = {
        dataId: directiviyData?.dataId,
        empDataId: emp.empDataId,
        growsImageMidTermCode: inputGrowsImageMidTermCode,
        growsImageLongTermCode: inputGrowsImageLongTermCode,
        courseId: inputCourseId,
        mostImportantRewardingCode: inputMostImportantRewardingCode,
        mostImportantWorkingCode: inputMostImportantWorkingCode,
        rewards: inputRewords,
        tag: inputTag,
        updatedAt: directiviyData.updatedAt,
      };

      (async () => {
        await handlePostResponse(postDirectiviy(directionals))
          .then((data) => {
            setDirectiviyData(data);
            dbDataToInputValues(data);
            setIsSave(true);
          })
          .catch((error) => {
            console.error("POSTリクエストでエラーが発生しました。");
          });
      })();
    }
    setIsEdit(false);
  };

  /**
   * 「キャンセル」ボタンクリックハンドラー
   */
  const onClickInputClearHandler = () => {
    if (directiviyData) {
      dbDataToInputValues(directiviyData);
    }
    reset(); // バリデーションメッセージのリセット
    setIsEdit(false);
  };

  return (
    <div style={isCardEditing(isEdit)}>
      {directiviyData && careerCourseMasterData && (
        <>
          <BasicInfoCardHeader
            headerTitle="指向性"
            onClickEditHandler={() => onClickEditHandler()}
            iconType="campaign"
            editable={IsCardEditable(emp.isEmployed)}
          />
          <CardContent>
            <FormControl>
              <InputLabel
                size="small"
                id="isGrowImageMidTermCode"
                required
                sx={selectRequiredItem}
              >
                成長イメージ（3年後）
              </InputLabel>
              <Select
                id="isGrowImageMidTermCode-select"
                labelId="isGrowImageMidTermCode"
                size="small"
                label="成長イメージ（3年後）"
                inputProps={{ readOnly: !isEdit }}
                sx={[{ width: "260px" }, isInputEditing(isEdit)]}
                value={inputGrowsImageMidTermCode}
                // バリデーションチェック
                {...register("growsImageMidTermCode", {
                  required: {
                    value: true,
                    message: "成長イメージ（3年後）は必須項目です。",
                  },
                })}
                error={"growsImageMidTermCode" in errors}
                onChange={(e) => {
                  if (typeof e.target.value === "string") {
                    setGrowImageMidTermCode(parseInt(e.target.value));
                  }
                }}
              >
                {/* プルダウン表示項目確定後修正 */}
                <MenuItem value={"0"}>現状維持</MenuItem>
                {/* 初期値の表示確認のため */}
                <MenuItem value={"1"}>初期値</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <InputLabel
                size="small"
                id="isGrowsImageLongTermCode"
                required
                sx={selectRequiredItem}
              >
                成長イメージ（4～6年後）
              </InputLabel>
              <Select
                id="isGrowsImageLongTermCode-select"
                labelId="isGrowsImageLongTermCode"
                size="small"
                label="成長イメージ（4～6年後）"
                inputProps={{ readOnly: !isEdit }}
                sx={[{ width: "260px" }, isInputEditing(isEdit)]}
                value={inputGrowsImageLongTermCode}
                // バリデーションチェック
                {...register("growsImageLongTermCode", {
                  required: {
                    value: true,
                    message: "成長イメージ（4～6年後））は必須項目です。",
                  },
                })}
                error={"growsImageLongTermCode" in errors}
                onChange={(e) => {
                  if (typeof e.target.value === "string") {
                    setGrowImageLongTermCode(parseInt(e.target.value));
                  }
                }}
              >
                {/* プルダウン表示項目確定後修正 */}
                <MenuItem value={"0"}>現状維持</MenuItem>
                {/* 初期値の表示確認のため */}
                <MenuItem value={"2"}>初期値</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <InputLabel
                size="small"
                id="isCourseId"
                required
                sx={selectRequiredItem}
              >
                キャリア志向
              </InputLabel>
              <Select
                id="isCourseId-select"
                labelId="isCourseId"
                size="small"
                label="キャリア志向"
                inputProps={{ readOnly: !isEdit }}
                sx={[{ width: "260px" }, isInputEditing(isEdit)]}
                value={inputCourseId}
                // バリデーションチェック
                {...register("courseId", {
                  required: {
                    value: true,
                    message: "キャリア志向は必須項目です。",
                  },
                })}
                error={"courseId" in errors}
                onChange={(e) => {
                  setCourseId(Number(e.target.value));
                }}
              >
                {careerCourseMasterData?.map((item) => (
                  <MenuItem value={item.courseId}>{item.courseName}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <InputLabel
                size="small"
                id="isMostImportantRewardingCode"
                required
                sx={selectRequiredItem}
              >
                やりがい1番重視
              </InputLabel>
              <Select
                id="isMostImportantRewardingCode-select"
                labelId="isMostImportantRewardingCode"
                size="small"
                label="やりがい1番重視"
                inputProps={{ readOnly: !isEdit }}
                sx={[{ width: "260px" }, isInputEditing(isEdit)]}
                value={inputMostImportantRewardingCode}
                // バリデーションチェック
                {...register("mostImportantRewardingCode", {
                  required: {
                    value: true,
                    message: "やりがい1番重視は必須項目です。",
                  },
                })}
                error={"mostImportantRewardingCode" in errors}
                onChange={(e) => {
                  if (typeof e.target.value === "string") {
                    setMostImportantRewardingCode(parseInt(e.target.value));
                  }
                }}
              >
                {/* プルダウン表示項目確定後修正 */}
                <MenuItem value={"0"}>貢献していると実感できること</MenuItem>
                {/* 初期値の表示確認のため */}
                <MenuItem value={"4"}>初期値</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <InputLabel
                size="small"
                id="isMostImportantWorkingCode"
                required
                sx={selectRequiredItem}
              >
                働き方1番重視
              </InputLabel>
              <Select
                id="isMostImportantWorkingCode-select"
                labelId="isMostImportantWorkingCode"
                size="small"
                label="働き方1番重視"
                inputProps={{ readOnly: !isEdit }}
                sx={[{ width: "260px" }, isInputEditing(isEdit)]}
                value={inputMostImportantWorkingCode}
                // バリデーションチェック
                {...register("mostImportantWorkingCode", {
                  required: {
                    value: true,
                    message: "働き方1番重視は必須項目です。",
                  },
                })}
                error={"mostImportantWorkingCode" in errors}
                onChange={(e) => {
                  if (typeof e.target.value === "string") {
                    setMostImportantWorkingCode(parseInt(e.target.value));
                  }
                }}
              >
                {/* プルダウン表示項目確定後修正 */}
                <MenuItem value={"0"}>ワークライフバランス</MenuItem>
                {/* 初期値の表示確認のため */}
                <MenuItem value={"5"}>初期値</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="tag"
                size="small"
                label="タグ"
                InputProps={{ readOnly: !isEdit }}
                sx={[{ width: "260px" }, isInputEditing(isEdit)]}
                value={inputTag}
                onChange={(e) => {
                  setTag(e.target.value);
                }}
              />
            </Box>

            {(errors.growsImageMidTermCode ||
              errors.growsImageLongTermCode ||
              errors.courseId ||
              errors.mostImportantRewardingCode ||
              errors.mostImportantWorkingCode) && (
              //ダイアログの表示
              <Dialog open={true} onClose={handleClose}>
                <DialogTitle>エラー</DialogTitle>
                <DialogContent>
                  {errors.growsImageMidTermCode && (
                    <DialogContentText>
                      {errors.growsImageMidTermCode.message}
                    </DialogContentText>
                  )}
                  {errors.growsImageLongTermCode && (
                    <DialogContentText>
                      {errors.growsImageLongTermCode.message}
                    </DialogContentText>
                  )}
                  {errors.courseId && (
                    <DialogContentText>
                      {errors.courseId.message}
                    </DialogContentText>
                  )}
                  {errors.mostImportantRewardingCode && (
                    <DialogContentText>
                      {errors.mostImportantRewardingCode.message}
                    </DialogContentText>
                  )}
                  {errors.mostImportantWorkingCode && (
                    <DialogContentText>
                      {errors.mostImportantWorkingCode.message}
                    </DialogContentText>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                    閉じる
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            <BasicFooterButton
              isEditing={isEdit}
              mright={1}
              onClickSave={handleSubmit(onClickButtonSaveHandler)}
              onClickCancel={onClickInputClearHandler}
            />
            <ToastMsg isDisp={isSave} isSuccess={true} />
          </CardContent>
        </>
      )}
    </div>
  );
};

export default Directional;
