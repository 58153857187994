import { useEffect, useState } from "react";
import BasicInfoCardHeader from "../../../common/BasicInfoCardHeader";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { EmpType } from "../../../../types/EmpType";
import { LaborType } from "../../../../types/basicInfo/LaborType";
import { getLabor, postLabor } from "./api/Api";
import { isCardEditing, isInputEditing } from "pages/common/LayoutFunction";
import BasicFooterButton from "../../../common/BasicFooterButton";
import { IsCardEditable } from "../../../common/IsEditable";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  handleGetResponse,
  handlePostResponse,
} from "components/handleResponse";
import { EmployeeWorkDetailType } from "types/basicInfo/EmployeeWorkDetailType";
import ToastMsg from "components/ToastMsg";

const Labor = (emp: EmpType) => {
  /* 状態管理パラメータ */
  // 編集状態を管理するフラグ (true:編集中、false:非編集中)
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // モーダルダイアログの開閉を管理するフラグ(true:開く、false:閉じる)
  const [isOpen, setIsopen] = useState<boolean>(true);
  // データを保存したかを管理するフラグ
  const [isSave, setIsSave] = useState<boolean>(false);

  /* DB値 */
  const [laborData, setLaborData] = useState<LaborType>(); // 労務情報(社員勤怠記録含む)
  const [employeeWorkDetail, setEmployeeWorkDetail] =
    useState<EmployeeWorkDetailType>();

  /* 画面表示項目 */
  const [
    inputCumulativeDifferenceInAssumedWorkingHours,
    setCumulativeDifferenceInAssumedWorkingHours,
  ] = useState(""); //累計想定労働時間差分
  const [
    inputAnnualOccurrencesOf45HoursOfOvertime,
    setAnnualOccurrencesOf45HoursOfOvertime,
  ] = useState<number | null>(null); //時間外45h年間回数
  const [inputPaidLeaveBalance, setPaidLeaveBalance] = useState<number | null>(
    null
  ); //有給残数
  const [
    inputPaidTimeOffUtilizationProgressRatio,
    setPaidTimeOffUtilizationProgressRatio,
  ] = useState(""); //有給消化進捗率
  const [inputIsLeaveOfAbsence, setIsLeaveOfAbsence] = useState(0); //休職中
  const [inputLeaveOfAbsenceTimes, setLeaveOfAbsenceTimes] = useState<
    number | null
  >(null); //休職回数
  const [inputIsMaternityAndParentalLeave, setIsMaternityAndParentalLeave] =
    useState(0); //産育休中
  const [
    inputMaternityAndParentalLeaveTimes,
    setMaternityAndParentalLeaveTimes,
  ] = useState<number | null>(null); //産育休回数
  const [inputEmploymentType, setEmploymentType] = useState(0); //雇用種別
  const [inputEmergencyContactName, setEmergencyContactName] = useState(""); //緊急連絡先氏名
  const [inputEmergencyContactNameKana, setEmergencyContactNameKana] =
    useState(""); //緊急連絡先氏名（カナ）
  const [inputEmergencyContactRelationship, setEmergencyContactRelationship] =
    useState(""); //緊急連絡先続柄
  const [inputEmergencyContactNumber, setEmergencyContactNumber] = useState(""); //緊急連絡先電話番号
  const [inputResignationAlert, setResignationAlert] = useState<number | null>(
    null
  ); // 退職アラート
  const [inputReasonForResignation, setReasonForResignation] = useState(""); //退職理由

  /**
   * DB値の反映処理
   *
   * @param employeeWorkDetail 労務情報
   */
  const dbDataToInputValues = (employeeWorkDetail: EmployeeWorkDetailType) => {
    // 入力項目に展開
    setCumulativeDifferenceInAssumedWorkingHours(
      String(employeeWorkDetail.cumulativeDifferenceInAssumedWorkingHours ?? "")
    );
    setAnnualOccurrencesOf45HoursOfOvertime(
      employeeWorkDetail.annualOccurrencesOf45HoursOfOvertime ?? null
    );
    setPaidLeaveBalance(employeeWorkDetail.paidLeaveBalance ?? null);
    setPaidTimeOffUtilizationProgressRatio(
      String(employeeWorkDetail.paidTimeOffUtilizationProgressRatio ?? "")
    );
    setIsLeaveOfAbsence(employeeWorkDetail.isLeaveOfAbsence ?? "");
    setLeaveOfAbsenceTimes(employeeWorkDetail.leaveOfAbsenceTimes ?? null);
    setIsMaternityAndParentalLeave(
      employeeWorkDetail.isMaternityAndParentalLeave ?? ""
    );
    setMaternityAndParentalLeaveTimes(
      employeeWorkDetail.maternityAndParentalLeaveTimes ?? null
    );
    setEmploymentType(employeeWorkDetail.employmentType ?? "");
    setEmergencyContactName(employeeWorkDetail.emergencyContactName ?? "");
    setEmergencyContactNameKana(
      employeeWorkDetail.emergencyContactNameKana ?? ""
    );
    setEmergencyContactRelationship(
      employeeWorkDetail.emergencyContactRelationship ?? ""
    );
    setEmergencyContactNumber(employeeWorkDetail.emergencyContactNumber ?? "");
    setResignationAlert(employeeWorkDetail.resignationAlert ?? null);
    setReasonForResignation(employeeWorkDetail.reasonForResignation ?? "");
  };

  /**
   * 初期処理
   */
  useEffect(() => {
    (async () => {
      await handleGetResponse(getLabor(emp.empDataId)).then((data) => {
        setLaborData(data);
        setEmployeeWorkDetail(data.employeeWorkDetail);
        dbDataToInputValues(data.employeeWorkDetail);
        setIsSave(false);
      });
    })();
  }, []);

  /**
   * 編集アイコンクリックハンドラー
   */
  const onClickEditHandler = () => {
    setIsEdit(true);
  };

  /**
   * 「保存」ボタンクリックハンドラー
   */
  const onClickButtonSaveHandler: SubmitHandler<
    EmployeeWorkDetailType
  > = () => {
    // 保存押下時に更新APiを起動
    // axios通信で引き渡すデータ
    if (employeeWorkDetail) {
      const inputEmployeeWorkDetail = {
        dataId: employeeWorkDetail.dataId,
        empDataId: emp.empDataId,
        /** 類型想定労働時間差分 */
        cumulativeDifferenceInAssumedWorkingHours:
          Number(inputCumulativeDifferenceInAssumedWorkingHours) || null,
        /** 時間外45h年間回数 */
        annualOccurrencesOf45HoursOfOvertime:
          inputAnnualOccurrencesOf45HoursOfOvertime,
        /** 有給残数 */
        paidLeaveBalance: inputPaidLeaveBalance,
        /** 有給消化進捗率 */
        paidTimeOffUtilizationProgressRatio:
          Number(inputPaidTimeOffUtilizationProgressRatio) || null,
        /** 休職中 */
        isLeaveOfAbsence: inputIsLeaveOfAbsence,
        /** 休職回数 */
        leaveOfAbsenceTimes: inputLeaveOfAbsenceTimes,
        /** 産育休中 */
        isMaternityAndParentalLeave: inputIsMaternityAndParentalLeave,
        /** 産育休回数 */
        maternityAndParentalLeaveTimes: inputMaternityAndParentalLeaveTimes,
        /** 雇用種別 */
        employmentType: inputEmploymentType,
        /** 緊急連絡先_氏名 */
        emergencyContactName: inputEmergencyContactName,
        /** 緊急連絡先_カナ */
        emergencyContactNameKana: inputEmergencyContactNameKana,
        /** 緊急連絡先_続柄 */
        emergencyContactRelationship: inputEmergencyContactRelationship,
        /** 緊急連絡先_電話番号 */
        emergencyContactNumber: inputEmergencyContactNumber,
        /** 退職理由 */
        reasonForResignation: inputReasonForResignation,
        /** 退職アラート */
        resignationAlert: inputResignationAlert,
        /** 更新日 */
        updatedAt: employeeWorkDetail.updatedAt,
      };
      if (laborData) {
        const inputLabor = {
          empDataId: emp.empDataId,
          employeeWorkDetail: inputEmployeeWorkDetail,
        };
        (async () => {
          await handlePostResponse(postLabor(inputLabor))
            .then((data) => {
              setLaborData(data);
              dbDataToInputValues(data);
              setIsSave(true);
            })
            .catch((error) => {
              console.error("POSTリクエストでエラーが発生しました。");
            });
        })();
      }
      setIsEdit(false);
    }
  };

  /**
   * 「キャンセル」ボタンクリックハンドラー
   */
  const onClickInputClearHandler = () => {
    if (employeeWorkDetail) {
      dbDataToInputValues(employeeWorkDetail);
    }
    setIsEdit(false);
  };

  /* 労務情報３か月分のテーブルデータ作成関数 */
  const generateLaborTabelData = (laborData: LaborType) => {
    // 返却用変数
    var tableDataList = [];
    // インデックス
    const dataIndex = {
      formattedDate: "",
      workingHours: "勤務時間",
      differenceInAssumedWorkingHours: "想定労働時間差分",
      absenceTimes: "欠勤回数",
      paidLeaveTimes: "有給回数",
      compensatoryLeaveTimes: "代休回数",
      summerVacationLeaveTimes: "夏季休暇",
      congratulationsAndCondolencesLeaveTimes: "慶弔休暇",
      onSiteWorkRatio: "オンサイト比率",
      offSiteWorkRatio: "オフサイト比率",
    };
    // テーブルデータ作成
    for (const [physicalName, logicalName] of Object.entries(dataIndex)) {
      var tableData = [];
      // th
      tableData.push(
        <TableCell
          key={logicalName}
          align={"center"}
          style={{ top: 57, minWidth: 60 }}
        >
          {logicalName}
        </TableCell>
      );

      // td
      if (laborData.employeeWorkLogs && laborData.employeeWorkLogs.length > 0) {
        for (let dataList of Object.values(laborData.employeeWorkLogs)) {
          if (physicalName in dataList) {
            for (const [physicalNameOfTypeName, data] of Object.entries(
              dataList
            )) {
              if (physicalName === physicalNameOfTypeName) {
                tableData.push(
                  <TableCell
                    key={logicalName + dataList.dataId}
                    align={"right"}
                    style={{ top: 57, minWidth: 50 }}
                  >
                    {data}
                  </TableCell>
                );
              }
            }
          }
        }
      }

      tableDataList.push(
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={"row_" + logicalName}
        >
          {tableData}
        </TableRow>
      );
    }
    return tableDataList;
  };

  // バリデーション用のオブジェクト、メソッドを定義
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<EmployeeWorkDetailType>();

  // モーダルダイアログの開閉を管理する関数
  const handleClose = () => {
    setIsopen(false);
    reset(); // フォームの値をリセット
  };

  return (
    <div style={isCardEditing(isEdit)}>
      {laborData && employeeWorkDetail && (
        <>
          <BasicInfoCardHeader
            headerTitle="労務"
            onClickEditHandler={() => onClickEditHandler()}
            iconType="group"
            editable={IsCardEditable(emp.isEmployed)}
          />
          <CardContent>
            <Box sx={{ mt: -1 }}>
              <TableContainer sx={{ maxHeight: 700 }}>
                <Table stickyHeader aria-label="sticky table">
                  {generateLaborTabelData(laborData)}
                </Table>
              </TableContainer>
            </Box>
            <Box
              sx={{ mt: 5 }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
              >
                <TextField
                  sx={isInputEditing(isEdit)}
                  size="small"
                  label="（累計想定労働時間差分）"
                  type="number"
                  InputProps={{ readOnly: !isEdit }}
                  value={inputCumulativeDifferenceInAssumedWorkingHours}
                  {...register("cumulativeDifferenceInAssumedWorkingHours", {
                    pattern: {
                      value: /^(0|\d{1,4}(\.\d{0,1})?)$/,
                      message:
                        "累計想定労働時間差分は整数4桁、小数点1桁で入力してください",
                    },
                  })}
                  error={"cumulativeDifferenceInAssumedWorkingHours" in errors}
                  onChange={(e) => {
                    setCumulativeDifferenceInAssumedWorkingHours(
                      e.target.value
                    );
                  }}
                />
                <TextField
                  sx={[{ ml: 2 }, isInputEditing(isEdit)]}
                  size="small"
                  label="（時間外45h年間回数）"
                  type="number"
                  InputProps={{ readOnly: !isEdit }}
                  value={inputAnnualOccurrencesOf45HoursOfOvertime ?? ""}
                  {...register("annualOccurrencesOf45HoursOfOvertime", {
                    pattern: {
                      value: /^(0|1[0-2]|\d)$/,
                      message:
                        "時間外45h年間回数は12以下の整数で入力してください",
                    },
                  })}
                  error={"annualOccurrencesOf45HoursOfOvertime" in errors}
                  onChange={(e) => {
                    setAnnualOccurrencesOf45HoursOfOvertime(
                      e.target.value === "" ? null : Number(e.target.value)
                    );
                  }}
                />

                <TextField
                  sx={[{ ml: 2 }, isInputEditing(isEdit)]}
                  size="small"
                  label="（有給残数）"
                  type="number"
                  InputProps={{ readOnly: !isEdit }}
                  value={inputPaidLeaveBalance ?? ""}
                  {...register("paidLeaveBalance", {
                    pattern: {
                      value: /^(?:[0-3]?\d{1}|40)$/,
                      message: "有給残数は40以下の整数で入力してください",
                    },
                  })}
                  error={"paidLeaveBalance" in errors}
                  onChange={(e) => {
                    setPaidLeaveBalance(
                      e.target.value === "" ? null : Number(e.target.value)
                    );
                  }}
                />
                <TextField
                  sx={[{ ml: 2 }, isInputEditing(isEdit)]}
                  size="small"
                  label="（有給消化進捗率）"
                  type="number"
                  InputProps={{ readOnly: !isEdit }}
                  value={inputPaidTimeOffUtilizationProgressRatio}
                  {...register("paidTimeOffUtilizationProgressRatio", {
                    pattern: {
                      value: /^(0|[1-9]?\d{1}(\.\d{0,2})?|100(\.0{0,2})?)$/,
                      message:
                        "有給消化進捗率は0.00~100.00の範囲で入力してください",
                    },
                  })}
                  error={"paidTimeOffUtilizationProgressRatio" in errors}
                  onChange={(e) => {
                    setPaidTimeOffUtilizationProgressRatio(e.target.value);
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                sx={{ mt: 2 }}
              >
                <FormControl>
                  <InputLabel size="small" id="healthInformation">
                    （休職中）
                  </InputLabel>
                  <Select
                    size="small"
                    label="（休職中）"
                    inputProps={{ readOnly: !isEdit }}
                    sx={[{ width: "223px" }, isInputEditing(isEdit)]}
                    value={inputIsLeaveOfAbsence}
                    onChange={(e) => {
                      // onChangeIsNewGraduate(e.target.name)
                      if (typeof e.target.value === "number") {
                        setIsLeaveOfAbsence(e.target.value);
                      }
                    }}
                  >
                    <MenuItem value={1}>YES</MenuItem>
                    <MenuItem value={0}>NO</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  sx={[{ ml: 2 }, isInputEditing(isEdit)]}
                  size="small"
                  label="（休職回数）"
                  type="number"
                  InputProps={{ readOnly: !isEdit }}
                  value={inputLeaveOfAbsenceTimes ?? ""}
                  {...register("leaveOfAbsenceTimes", {
                    pattern: {
                      value: /^(\d{1})$/,
                      message: "休職回数は整数1桁で入力してください",
                    },
                  })}
                  error={"leaveOfAbsenceTimes" in errors}
                  onChange={(e) => {
                    setLeaveOfAbsenceTimes(
                      e.target.value === "" ? null : Number(e.target.value)
                    );
                  }}
                />

                <FormControl>
                  <InputLabel
                    size="small"
                    id="healthInformation"
                    sx={{ ml: 2 }}
                  >
                    （産育休中）
                  </InputLabel>
                  <Select
                    size="small"
                    label="（産育休中）"
                    inputProps={{ readOnly: !isEdit }}
                    sx={[{ ml: 2, width: "223px" }, isInputEditing(isEdit)]}
                    value={inputIsMaternityAndParentalLeave}
                    onChange={(e) => {
                      // onChangeIsNewGraduate(e.target.name)
                      if (typeof e.target.value === "number") {
                        setIsMaternityAndParentalLeave(e.target.value);
                      }
                    }}
                  >
                    <MenuItem value={0}>―</MenuItem>
                    <MenuItem value={1}>産休</MenuItem>
                    <MenuItem value={2}>育休</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  sx={[{ ml: 2 }, isInputEditing(isEdit)]}
                  size="small"
                  label="（産育休回数）"
                  type="number"
                  InputProps={{ readOnly: !isEdit }}
                  value={inputMaternityAndParentalLeaveTimes ?? ""}
                  {...register("maternityAndParentalLeaveTimes", {
                    pattern: {
                      value: /^(\d{1})$/,
                      message: "産育休回数は整数1桁で入力してください",
                    },
                  })}
                  error={"maternityAndParentalLeaveTimes" in errors}
                  onChange={(e) => {
                    setMaternityAndParentalLeaveTimes(
                      e.target.value === "" ? null : Number(e.target.value)
                    );
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                sx={{ mt: 2 }}
              >
                <TextField
                  sx={[isInputEditing(isEdit)]}
                  size="small"
                  label="緊急連絡先氏名"
                  InputProps={{ readOnly: !isEdit }}
                  value={inputEmergencyContactName}
                  {...register("emergencyContactName", {
                    pattern: {
                      value: /^(.{0,40})$/,
                      message: "緊急連絡先氏名は40字以下で入力してください",
                    },
                  })}
                  error={"emergencyContactName" in errors}
                  onChange={(e) => {
                    setEmergencyContactName(e.target.value);
                  }}
                />
                <TextField
                  sx={[{ ml: 2 }, isInputEditing(isEdit)]}
                  size="small"
                  label="緊急連絡先氏名（カナ）"
                  InputProps={{ readOnly: !isEdit }}
                  value={inputEmergencyContactNameKana}
                  {...register("emergencyContactNameKana", {
                    pattern: {
                      value: /^[ァ-ヴー\s]{0,40}$/,
                      message:
                        "緊急連絡先氏名（カナ）はカタカナ40字以下で入力してください",
                    },
                  })}
                  error={"emergencyContactNameKana" in errors}
                  onChange={(e) => {
                    setEmergencyContactNameKana(e.target.value);
                  }}
                />

                <TextField
                  sx={[{ ml: 2 }, isInputEditing(isEdit)]}
                  size="small"
                  label="緊急連絡先続柄"
                  InputProps={{ readOnly: !isEdit }}
                  value={inputEmergencyContactRelationship}
                  {...register("emergencyContactRelationship", {
                    pattern: {
                      value: /^.{0,255}$/,
                      message: "緊急連絡先続柄は255字以下で入力してください",
                    },
                  })}
                  error={"emergencyContactRelationship" in errors}
                  onChange={(e) => {
                    setEmergencyContactRelationship(e.target.value);
                  }}
                />
                <TextField
                  sx={[{ ml: 2 }, isInputEditing(isEdit)]}
                  size="small"
                  label="緊急連絡先電話番号"
                  InputProps={{ readOnly: !isEdit }}
                  value={inputEmergencyContactNumber}
                  {...register("emergencyContactNumber", {
                    pattern: {
                      value: /^[\d\\-]{0,20}$/,
                      message: "緊急連絡先電話番号は20桁以下で入力してください",
                    },
                  })}
                  error={"emergencyContactNumber" in errors}
                  onChange={(e) => {
                    setEmergencyContactNumber(e.target.value);
                  }}
                />
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                width="940px"
                sx={{ mt: 2 }}
              >
                <FormControl>
                  <InputLabel size="small" id="employmentType">
                    （雇用種別）
                  </InputLabel>
                  <Select
                    size="small"
                    label="（雇用種別）"
                    inputProps={{ readOnly: !isEdit }}
                    sx={[{ width: "223px" }, isInputEditing(isEdit)]}
                    value={inputEmploymentType}
                    onChange={(e) => {
                      if (typeof e.target.value === "number") {
                        setEmploymentType(e.target.value);
                      }
                    }}
                  >
                    <MenuItem value={0}>フルタイム</MenuItem>
                    <MenuItem value={1}>時短</MenuItem>
                  </Select>
                </FormControl>

                {/* TODO：TALENT_MNG-385 労務：退職アラートの仕様未決定 */}
                <TextField
                  sx={[{ ml: 2 }, isInputEditing(isEdit)]}
                  size="small"
                  label="（退職アラート）"
                  type="number"
                  value={inputResignationAlert ?? ""}
                  InputProps={{ readOnly: !isEdit }}
                  onChange={(e) => {
                    setResignationAlert(
                      e.target.value === "" ? null : Number(e.target.value)
                    );
                  }}
                />
                <TextField
                  sx={[{ ml: 2 }, isInputEditing(isEdit)]}
                  size="small"
                  label="（退職理由）"
                  InputProps={{ readOnly: !isEdit }}
                  value={inputReasonForResignation}
                  {...register("reasonForResignation", {
                    pattern: {
                      value: /^.{0,255}$/,
                      message: "退職理由は255字以下で入力してください",
                    },
                  })}
                  error={"reasonForResignation" in errors}
                  onChange={(e) => {
                    setReasonForResignation(e.target.value);
                  }}
                />
                {(errors.cumulativeDifferenceInAssumedWorkingHours ||
                  errors.annualOccurrencesOf45HoursOfOvertime ||
                  errors.paidLeaveBalance ||
                  errors.paidTimeOffUtilizationProgressRatio ||
                  errors.leaveOfAbsenceTimes ||
                  errors.maternityAndParentalLeaveTimes ||
                  errors.emergencyContactName ||
                  errors.emergencyContactNameKana ||
                  errors.emergencyContactRelationship ||
                  errors.emergencyContactNumber ||
                  errors.reasonForResignation) && (
                  // ダイアログの表示
                  <Dialog open={true} onClose={handleClose}>
                    <DialogTitle>エラー</DialogTitle>
                    <DialogContent>
                      {errors.cumulativeDifferenceInAssumedWorkingHours && (
                        <DialogContentText>
                          {
                            errors.cumulativeDifferenceInAssumedWorkingHours
                              .message
                          }
                        </DialogContentText>
                      )}
                      {errors.annualOccurrencesOf45HoursOfOvertime && (
                        <DialogContentText>
                          {errors.annualOccurrencesOf45HoursOfOvertime.message}
                        </DialogContentText>
                      )}
                      {errors.paidLeaveBalance && (
                        <DialogContentText>
                          {errors.paidLeaveBalance.message}
                        </DialogContentText>
                      )}
                      {errors.paidTimeOffUtilizationProgressRatio && (
                        <DialogContentText>
                          {errors.paidTimeOffUtilizationProgressRatio.message}
                        </DialogContentText>
                      )}
                      {errors.leaveOfAbsenceTimes && (
                        <DialogContentText>
                          {errors.leaveOfAbsenceTimes.message}
                        </DialogContentText>
                      )}
                      {errors.maternityAndParentalLeaveTimes && (
                        <DialogContentText>
                          {errors.maternityAndParentalLeaveTimes.message}
                        </DialogContentText>
                      )}
                      {errors.emergencyContactName && (
                        <DialogContentText>
                          {errors.emergencyContactName.message}
                        </DialogContentText>
                      )}
                      {errors.emergencyContactNameKana && (
                        <DialogContentText>
                          {errors.emergencyContactNameKana.message}
                        </DialogContentText>
                      )}
                      {errors.emergencyContactRelationship && (
                        <DialogContentText>
                          {errors.emergencyContactRelationship.message}
                        </DialogContentText>
                      )}
                      {errors.emergencyContactNumber && (
                        <DialogContentText>
                          {errors.emergencyContactNumber.message}
                        </DialogContentText>
                      )}
                      {errors.reasonForResignation && (
                        <DialogContentText>
                          {errors.reasonForResignation.message}
                        </DialogContentText>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} autoFocus>
                        閉じる
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
                <BasicFooterButton
                  isEditing={isEdit}
                  mtop={0}
                  mright={-30}
                  onClickSave={handleSubmit(onClickButtonSaveHandler)}
                  onClickCancel={onClickInputClearHandler}
                />
              </Box>
              <ToastMsg isDisp={isSave} isSuccess={true} />
            </Box>
          </CardContent>
        </>
      )}
    </div>
  );
};

export default Labor;
